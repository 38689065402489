import React from "react";
import { DeleteButton } from "../../components/Attributes/Button";
import { getPathUrl } from "../../function";
import useSweetAlert from "../../utils/useSweetAlert";

const CradleCard = ({ cradle, deleteCradle, editCradles }) => {
  const { confirm } = useSweetAlert();
  const clickDeleteCard = async (e, id) => {
    e.stopPropagation();
    await confirm({
      title: "คุณต้องการลบการ์ดนี้หรือไม่",
      callback: () => deleteCradle(id),
    });
  };

  return (
    <div>
      <div
        className="bg-white justify-center shadow-md w-64 h-64 p-4 rounded-lg m-2  cursor-pointer 
        flex flex-col  flex-grow grid-cols-1  mt-10 mb-10 hover:shadow-stone-300 hover:shadow-xl duration-300  text-left "
        onClick={() => {
          editCradles(cradle?._id);
        }}
      >
        <div className="text-left border-b-2 space-x-1 text-lg  pt-2 px-3">
          {cradle?.cradle_name}
        </div>

        <div className=" flex justify-center w-44 h-28 mx-auto ">
          <img src={getPathUrl(cradle?.cradle_image_cover)} />
        </div>
        <DeleteButton
          className=" mt-5 content-end  p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-full border border-red-700 hover:border-red-500 hover:bg-red-500 text-red-700 hover:text-white  rounded-full duration-300"
          onClick={(e) => clickDeleteCard(e, cradle?._id)}
        />
      </div>
    </div>
  );
};

export default CradleCard;
