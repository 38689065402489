import React, { useEffect, useState } from "react";
import api from "../../api";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Attributes/Button";
import { DeleteButton } from "../../components/Attributes/Button";
import YouTube, { YouTubeProps } from "react-youtube";
import Input from "../../components/Field/Input";
import useToastify from "../../utils/useToastify";
import Swal from "sweetalert2";
import useSweetAlert from "../../utils/useSweetAlert";

const YoutubeCard = {};

const YoutubeMain = () => {
  const router = useNavigate();
  const toastify = useToastify();
  const { confirm } = useSweetAlert();

  const [isNew, setIsNew] = useState(false);
  const [newUrl, setNewUrl] = useState(null);
  const [youtubes, setYoutubes] = useState([]);
  const [loading, setLoading] = useState(true);

  const onClickAddYoutube = () => {
    setIsNew(true);
  };

  useEffect(() => {
    LoadDataYoutube();
  }, []);

  const LoadDataYoutube = async () => {
    try {
      setLoading(true);
      const { data } = await api.get("/youtubes");
      setYoutubes(data?.youtubes);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onClickSubmitYoutube = async () => {
    try {
      await api.post("/youtubes", {
        url: newUrl,
      });
      await Swal.fire("Success", "", "success");
      await LoadDataYoutube();
      setIsNew(false);
    } catch (error) {
      console.log("submit youtube error", error);
    }
  };

  const deleteYoutube = async (id) => {
    try {
      await api.delete(`/youtubes/${id}`);
      await Swal.fire("Success", "", "success");
      await LoadDataYoutube();
    } catch (error) {
      console.log("delete youtube error", error);
    }
  };

  const clickDeleteCard = async (e, id) => {
    e.stopPropagation();
    await confirm({
      title: "คุณต้องการลบการ์ดนี้หรือไม่",
      callback: () => deleteYoutube(id),
    });
  };
  const onPlayerReady = (event) => {
    event.target.pauseVideo();
  };

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };

  const convertUrlYoutube = (newUrl = "") => {
    if (newUrl) {
      const url = newUrl;
      const urlSplit = url?.split("=");
      if (urlSplit?.length > 1) {
        const urlSplit2 = urlSplit[1]?.split("&");
        const urlSplit3 = urlSplit2[0];
        return urlSplit3;
      }
      return "";
    }
    return "";
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className=" pl-72">
      <div className=" space-x-3 my-4 ">
        <Button onClick={onClickAddYoutube} text={"Add Youtube"} />
        {/* NEW */}
        {isNew && (
          <div className=" bg-white shadow-md relative w-80 h-72 p-4  rounded-lg  cursor-pointer mt-10  hover:shadow-stone-300 hover:shadow-xl duration-300  text-left ">
            <div>
              <Input
                value={newUrl}
                onChange={(e) => setNewUrl(e.target.value)}
                label="URL"
              />
              <div className="">
                <YouTube
                  videoId={convertUrlYoutube(newUrl)}
                  opts={opts}
                  onReady={onPlayerReady}
                />
              </div>
            </div>
            <div className="flex justify-end space-x-1 mt-1">
              <Button
                className=" w-16"
                text="Submit"
                onClick={onClickSubmitYoutube}
              />
            </div>
          </div>
        )}

        {/* DISPLAY */}
        {!isNew && (
          <div className="grid grid-cols-3">
            {youtubes?.map((youtube, index) => {
              return (
                <div className=" bg-white shadow-md relative w-80 h-72 p-4  rounded-lg  cursor-pointer flex flex-col  flex-grow  mt-10  hover:shadow-stone-300 hover:shadow-xl duration-300  text-left ">
                  <div>
                    {/* URL */}
                    <div className="">
                      <YouTube
                        videoId={convertUrlYoutube(youtube.url)}
                        opts={opts}
                        onReady={onPlayerReady}
                      />
                    </div>
                  </div>
                  <div className="flex justify-end space-x-1 mt-1">
                    <DeleteButton
                      className=" w-16"
                      text={"Delete"}
                      onClick={(e) => clickDeleteCard(e, youtube?._id)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
export default YoutubeMain;
