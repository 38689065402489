import { useId } from "react";
import classNames from "classnames";
import { getPathUrl } from "../../function";
import useSweetAlert from "../../utils/useSweetAlert";
// import classNames from "classnames";
import api from "../../api";

export const FieldUploadMultiple = ({
  title,
  name,
  value = [],
  label = "อัปโหลดรูปภาพ",
  setValue,
  imageStyle = "h-40 w-40 object-fill",
  mode = "state",
  index = 0,
  onClickUploadMultiple,
  disabledUpload = false,
  loadSponser,
  sponserId,
  sponserType,
}) => {
  const idUpload = useId();
  const { confirm } = useSweetAlert();

  const onClickOpenUpload = () => {
    const docUpload = document.getElementById(idUpload);
    docUpload.click();
  };

  const FunctionSetImageToArray = ({ target: { files } }) => {
    let myFiles = [...value];
    for (const file of files) {
      myFiles.push({ url: URL.createObjectURL(file), file, type: "local" });
    }
    setValue(myFiles);
  };

  const deleteSponser = async (id) => {
    const deleteItems = value.filter((item, index) => index !== id);

    await api.put("/sponsers/" + sponserId, {
      sponser_images: deleteItems.map((item) => item.url),
      sponser_type: sponserType,
    });

    await loadSponser();
  };

  const clickDeleteSponsor = async (e, id) => {
    e.stopPropagation();
    deleteSponser(id);
  };

  const deleteSponserLocal = async (e, id) => {
    e.stopPropagation();

    const deleteItems = value.filter((item, index) => index !== id);
    setValue(deleteItems);
  };
  return (
    <div className=" w-full">
      <div class="mt-2  space-y-3">
        {value.length > 0 ? (
          <div className="grid grid-cols-4 rounded-lg border-4 space-x-2 border-dashed w-5/6 h-72 p-2 group overflow-auto">
            {value.map((valueItem, index) => {
              if (valueItem.type === "local") {
                return (
                  <div className="">
                    <img
                      className=" h-40 w-40"
                      key={index}
                      src={valueItem.url}
                    />
                    <button
                      onClick={(e) => deleteSponserLocal(e, index)}
                      class=" my-2 w-full h-5 text-xs flex justify-center bg-red-500 text-gray-100 p-2  rounded-full tracking-wide items-center
            font-semibold  focus:outline-none focus:shadow-outline hover:bg-red-700 shadow-lg cursor-pointer transition ease-in duration-300"
                    >
                      Delete
                    </button>
                  </div>
                );
              }

              return (
                <div className="">
                  <img
                    className=" h-60 w-60"
                    key={index}
                    src={getPathUrl(valueItem.url)}
                  />
                  <div>
                    <button
                      onClick={(e) => clickDeleteSponsor(e, index)}
                      class=" my-2 w-full h-5 text-xs flex justify-center bg-red-500 text-gray-100 p-2  rounded-full tracking-wide items-center
                      font-semibold  focus:outline-none focus:shadow-outline hover:bg-red-700 shadow-lg cursor-pointer transition ease-in duration-300"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div class="grid grid-cols-1 space-y-2">
            <div class={classNames("flex items-center justify-center w-full")}>
              <label class="flex flex-col rounded-lg border-4 border-dashed w-full h-60 p-10 group text-center">
                <div
                  class={classNames(
                    "h-full w-full text-center flex flex-col items-center"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-10 h-10 text-blue-600 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <p class="pointer-none text-gray-500 ">
                    select a file from your computer
                  </p>
                </div>
              </label>
            </div>
          </div>
        )}

        <div className=" flex  ">
          <input
            accept="image/jpeg,image/gif,image/png"
            multiple
            onChange={FunctionSetImageToArray}
            id={idUpload}
            type="file"
            hidden
          />

          <div>
            <button
              onClick={onClickOpenUpload}
              class=" my-5 w-24 h-10 flex justify-center bg-blue-500 text-gray-100 p-4  rounded-full tracking-wide items-center
            font-semibold  focus:outline-none focus:shadow-outline hover:bg-blue-700 shadow-lg cursor-pointer transition ease-in duration-300"
            >
              Select
            </button>
          </div>
          <div className=" ml-6 ">
            <button
              // type="submit"
              disabled={disabledUpload}
              onClick={disabledUpload ? () => {} : onClickUploadMultiple}
              className={classNames(
                "my-5 w-24 h-10 flex justify-center text-gray-100 p-4  rounded-full tracking-wide items-center font-semibold  focus:outline-none focus:shadow-outline  shadow-lg cursor-pointer",
                {
                  "bg-blue-500 hover:bg-blue-700": !disabledUpload,
                  "bg-gray-400": disabledUpload,
                }
              )}
            >
              {/* {disabledUpload ? "Loading..." : "Upload"} */}
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FieldUpload = ({
  title,
  name,
  value = "",
  label = "อัปโหลดรูปภาพ",
  setValue,
  imageStyle = "h-40 w-40 object-fill",
  mode = "state",
  disabledUpload = false,
  index = 0,
  onClickUpload,
}) => {
  const idUpload = useId();

  const onClickOpenUpload = () => {
    const docUpload = document.getElementById(idUpload);
    docUpload.click();
  };

  const FunctionSetImageToState = async ({ target: { files } }) => {
    const file = await files[0];
    setValue({ file, url: URL.createObjectURL(file), type: "local" });
  };

  return (
    <div className=" w-full">
      <div class="mt-2 space-y-3">
        {value ? (
          <div className={imageStyle}>
            <img
              className={imageStyle}
              key={index}
              src={value.type === "local" ? value.url : getPathUrl(value.url)}
            />
          </div>
        ) : (
          <div class="grid grid-cols-1 space-y-2">
            <div class={classNames("flex items-center justify-center w-full")}>
              <label class="flex flex-col rounded-lg border-4 border-dashed w-full h-60 p-10 group text-center">
                <div
                  class={classNames(
                    "h-full w-full text-center flex flex-col items-center"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-10 h-10 text-blue-600 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    />
                  </svg>
                  <p class="pointer-none text-gray-500 ">
                    select a file from your computer
                  </p>
                </div>
              </label>
            </div>
          </div>
        )}

        <div className=" flex  ">
          <input
            accept="image/jpeg,image/gif,image/png"
            onChange={FunctionSetImageToState}
            id={idUpload}
            type="file"
            hidden
          />
          <div>
            <button
              onClick={onClickOpenUpload}
              class=" my-5 w-24 h-10 flex justify-center bg-blue-500 text-gray-100 p-4  rounded-full tracking-wide items-center
              font-semibold  focus:outline-none focus:shadow-outline hover:bg-blue-700 shadow-lg cursor-pointer transition ease-in duration-300"
            >
              Select
            </button>
          </div>
          <div className=" ml-6 ">
            <button
              onClick={disabledUpload ? () => {} : onClickUpload}
              // type="submit"
              className={classNames(
                "my-5 w-24 h-10 flex justify-center text-gray-100 p-4  rounded-full tracking-wide items-center font-semibold  focus:outline-none focus:shadow-outline  shadow-lg cursor-pointer",
                {
                  "bg-blue-500 hover:bg-blue-700": !disabledUpload,
                  "bg-gray-400": disabledUpload,
                }
              )}
            >
              {/* {disabledUpload ? "Loading..." : "Upload"} */}
              Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
