import React from "react";

const HomeMain = () => {
  return (
    <div className="pl-72 font-Prompt mx-auto">
      <div className=" mt-20"></div>
    </div>
  );
};
export default HomeMain;
