import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";
import HomeMain from "./pages/Home/HomeMain";
import BannerMain from "./pages/Banner/BannerMain";
import BlogMain from "./pages/Blog/BlogMain";
import Sidebar from "./components/Layout/Sideber";
import Amplify from "aws-amplify";
import YoutubeMain from "./pages/Youtube/YoutubeMain";

//CSS
import "react-toastify/dist/ReactToastify.css";
import AddBlog from "./pages/Blog/AddBlog";
import BlogCard from "./pages/Blog/BlogCard";

import CartypeMain from "./pages/Cartype/CartypeMain";
import AddCartype from "./pages/Cartype/AddCartype";

import AddInsurance from "./pages/insurance/AddInsurance";
import InsuranceMain from "./pages/insurance/InsuranceMain";

import CardleMain from "./pages/Cradle/CradleMain";
import AddCradle from "./pages/Cradle/AddCradle";

import CradletypeMain from "./pages/Cradletype/CradletypeMain";
import AddCradletype from "./pages/Cradletype/AddCradletype";

import ProfileMain from "./pages/Profile/ProfileMain";
import Login from "./pages/Login";
import { useUserStore } from "./_store/userStore";
import ProtectedRoute from "./components/ProtectedRoute";
import CreateUser from "./pages/CreateUser";
import Logout from "./components/Layout/Logout";
import UserrequestsMain from "./pages/Userrequests/UserrequestsMain";
import SponserMain from "./pages/Sponser/SponserMain";

Amplify.configure({
  Auth: {
    identityPoolId: "ap-southeast-1:2109a44a-25ed-441f-8abc-9c0b340f8192",
    region: "ap-southeast-1",
    // userPoolId: "ap-southeast-1_oMsdo6YpE",
    userPoolId: "ap-southeast-1_3oXiGkRgE",
    userPoolWebClientId: "2du8mcovtd6ussfp6sslvmapd3",
  },
  Storage: {
    bucket: "dashboardassobdb059558f784d72b8e6843e252ac028160536-dev",
    region: "ap-southeast-1",
  },
});

function App() {
  const [showSidebar, setShowSideber] = useState(true);

  const user = useUserStore((state) => state.user);
  // const location = useLocation();
  // const isActive = location?.pathname === "/login";
  console.log("user", user);

  return (
    <Router>
      <React.Fragment>
        <Sidebar setShowSidebar={setShowSideber} showSidebar={showSidebar} />
        <div>
          <div
            className={
              "hidden md:block relative font-Prompt text-2xl mt-5 uppercase font-bold text-slate-700 text-center " +
              (showSidebar ? "mx-auto" : "")
            }
          >
            สมาคมการซ่อมรถยนต์แห่งประเทศไทย
          </div>

          <div
            className={
              "hidden md:block relative font-Prompt text-2xl uppercase font-bold text-red-900 text-center " +
              (showSidebar ? "mx-auto" : "")
            }
          >
            Auto repair Association of Thailand
          </div>
        </div>
        <div className="text-end">{user && <Logout />}</div>
      </React.Fragment>

      <div className={"relative" + (showSidebar ? "md:ml-64" : "")}>
        <Routes>
          <Route element={<ProtectedRoute user={user} />}>
            <Route path="/" element={<HomeMain />} />
            <Route path="/banner" element={<BannerMain />} />
            <Route path="/sponser" element={<SponserMain />} />
            <Route path="/youtube" element={<YoutubeMain />} />

            <Route exact path="/blog" element={<BlogMain />} />
            <Route path="/blog/addBlog" element={<AddBlog />} />
            <Route path="/blog/editBlog/:id" element={<AddBlog />} />

            <Route exact path="/cartype" element={<CartypeMain />} />
            <Route path="/cartype/addcartype" element={<AddCartype />} />
            <Route path="/cartype/editcartype/:id" element={<AddCartype />} />

            <Route exact path="/insurance" element={<InsuranceMain />} />
            <Route path="/insurance/addinsurance" element={<AddInsurance />} />
            <Route
              path="/insurance/editinsurance/:id"
              element={<AddInsurance />}
            />

            <Route exact path="/youtube" element={<YoutubeMain />} />

            <Route exact path="/cradle" element={<CardleMain />} />
            <Route exact path="/cradle/addcradle" element={<AddCradle />} />
            <Route
              exact
              path="/cradle/editcradle/:id"
              element={<AddCradle />}
            />

            <Route exact path="/cradletype" element={<CradletypeMain />} />
            <Route
              exact
              path="/cradletype/addcradletype"
              element={<AddCradletype />}
            />
            <Route
              exact
              path="/cradletype/editcradletype/:id"
              element={<AddCradletype />}
            />
            <Route exact path="/profile" element={<ProfileMain />} />
            <Route exact path="/userrequest" element={<UserrequestsMain />} />
            <Route exact path="/" element={<ProfileMain />} />
            <Route exact path="/createuser" element={<CreateUser />} />
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
