import { API, Auth } from "aws-amplify";
import React from "react";
import {
  ApproveButton,
  RejectButton,
} from "../../components/Attributes/Button";
import { getPathUrl } from "../../function";
import api from "../../api";
import { EmitterStatus } from "../../event/EmitterStatus";

const ProfileCard = ({ profile, loadProfile }) => {
  const onClickReject = async () => {
    try {
      await api.put(`/profiles/${profile?._id}`, {
        register_status: "F",
      });
      await loadProfile();
    } catch (error) {
      console.log("ERROR TO LOAD PROFILE :", error);
    } finally {
    }
  };

  const onClickApprove = async () => {
    try {
      const password = new Date().getTime();
      console.log("password", password);
      await Auth.signUp({
        username: profile.email,
        password: `${password}`,
        attributes: {
          ["custom:_id"]: profile._id,
          email: profile.email,
        },
      });
      await api.put(`/profiles/${profile?._id}`, {
        register_status: "S",
      });
      await loadProfile();
    } catch (error) {
      await EmitterStatus("ERROR", { text: error });
    } finally {
    }
  };

  return (
    <div className=" ">
      <div className="bg-white justify-center shadow-md w-72 h-40 p-2 rounded-lg m-1  cursor-pointer flex flex-col  flex-grow grid-cols-1  mt-10  hover:shadow-stone-300 hover:shadow-xl duration-300  text-left ">
        <div className="text-left border-b-2 space-x-1 text-base h-auto w-auto  pt-2 px-3">
          อีเมล: {profile?.email}
        </div>
        <div className="text-left border-b-2 space-x-1 text-base h-auto w-auto pt-2 px-3">
          ชื่อ: {profile?.firstname} {profile?.lastname}
        </div>
        {/* <div className="text-left border-b-2 space-x-1 text-base h-auto w-auto pt-2 px-3">
          {profile?.register_message}
        </div> */}
        <div className=" flex justify-center w-32 h-28 mx-auto "></div>
        <div className="flex justify-between">
          {/* <div className=" my-5">
            <ApproveButton onClick={onClickApprove} />
          </div> */}
          {/* <div className=" my-5">
            <RejectButton onClick={onClickReject} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
