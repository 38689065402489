export const optionsBlogType = [
  { label: "ข่าวสาร", value: "NEWS" },
  { label: "กิจกรรม", value: "ACTIVITY" },
  { label: "ประชาสัมพันธ์", value: "RELATIONS" },
];

export const optionsPayMent = [
  { label: "โอนเงิน", value: "โอนเงิน" },
  { label: "เงินสด", value: "เงินสด" },
  { label: "เงินสด+โอนเงิน", value: "เงินสด+โอนเงิน" },
];
