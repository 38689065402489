import axios from "axios";

const dev = process.env.NODE_ENV === "development";

const instance = axios.create({
  baseURL: dev
    ? "http://localhost:1337"
    : // ? "https://63ees32y4k.execute-api.ap-southeast-1.amazonaws.com/dev"
      "https://63ees32y4k.execute-api.ap-southeast-1.amazonaws.com/dev",
});

export default instance;
