import { Page } from "radium-omnibus";
import React from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { AddButton } from "../../components/Attributes/Button";
import InsuranceCard from "./InsuranceCard";
import useSweetAlert from "../../utils/useSweetAlert";
import { useEffect, useState } from "react";

const InsuranceMain = () => {
  const navigate = useNavigate();
  const [insurances, setInsurances] = useState([]);
  const [loading, setLoading] = useState(false);
  const { confirm, successDeleteAlert } = useSweetAlert();

  useEffect(() => {
    loadInsurances();
  }, []);

  const loadInsurances = async () => {
    try {
      setLoading(true);
      const { data: responseData } = await api.get("/insurances");
      setInsurances(responseData?.insurances);
    } catch (error) {
      console.log("ERROR TO LOAD INSURANCE :", error);
    } finally {
      setLoading(false);
    }
  };
  const editInsurances = async (id) => {
    navigate(`/Insurance/editInsurance/${id}`);
  };

  const deleteInsurance = async (id) => {
    try {
      await api.delete(`/insurances/${id}`);
      successDeleteAlert();
      await loadInsurances();
    } catch (error) {
      console.error("ERROR TO DELETE INSURANCE :", error);
    } finally {
    }
  };

  return (
    <div className="pl-72 mb-10">
      <div className=" ">
        <AddButton
          title={" Add Insurance + "}
          onClick={() => {
            navigate("/insurance/AddInsurance");
          }}
        />
      </div>
      <div className="grid grid-cols-3 ">
        {insurances?.map((insurance, index) => {
          return (
            <InsuranceCard
              key={index}
              insurance={insurance}
              deleteInsurance={deleteInsurance}
              editInsurances={editInsurances}
            />
          );
        })}
      </div>
    </div>
  );
};
export default InsuranceMain;
