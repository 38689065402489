import { useEffect, useState } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useUserStore } from "../../_store/userStore";

const Login = () => {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  console.log("user", user);

  useEffect(() => {
    if (user) {
      navigate("/banner");
    } else {
      return;
    }
  }, [user]);

  const [loading, setLoading] = useState(false);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const onClickLogin = async () => {
    if (username === "admin" && password === "1234") {
      setUser("admin");
      setUserName("");
      setPassword("");
      setError(false);
    } else {
      setError(true);
    }
  };

  // const handleKeypress = (e) => {
  //   if (e.key === "Enter") {
  //     onClickLogin();
  //   }
  // };

  return (
    <div>
      <div className="absolute top-1/2 left-1/2 md:left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full">
        <div className=" w-80 h-auto mx-auto">
          <div className="mx-auto">
            <img src="images/llogo.png" className="mx-auto w-28 "></img>
          </div>
          <div className="">
            <div className="   text-lg text-sky-900 mt-4">username</div>
            <input
              onChange={(e) => setUserName(e.target.value)}
              value={username}
              className="font-light appearance-none rounded relative block w-full px-3 py-3 border border-gray-300 
                    placeholder-asso-grayassociation  focus:outline-none  focus:z-10 text-sm"
              type="email"
              required
              autoComplete="email"
              name="username"
            />
          </div>
          <div className="">
            <div className="    text-lg text-sky-900 mt-4">password</div>
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              className="font-light appearance-none rounded relative block w-full px-3 py-3 border border-gray-300 
                    placeholder-asso-grayassociation  focus:outline-none  focus:z-10 text-sm"
              type="password"
              required
              autoComplete="email"
              name="username"
            />
          </div>
        </div>
        <div className="bg-red-700 w-36  rounded-full mx-auto ">
          <div
            onClick={onClickLogin}
            className=" cursor-pointer text-center text-sm text-white py-3 mt-10"
          >
            LOG IN
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
