import React from "react";
import api from "../../api";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import UserrequestsCard from "./UserrequestsCard";

const UserrequestsMain = () => {
  const [loading, setLoading] = useState(false);
  const [userrequest, setUserrequest] = useState([]);
  const [error, setError] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    loadUserrequest();
  }, []);

  const loadUserrequest = async () => {
    try {
      setLoading(true);
      const { data: responseData } = await api.get(`/userrequests?`, {
        params: {
          register_status: "P",
        },
      });
      console.log("responseData", responseData);
      setUserrequest(responseData?.userrequests);
    } catch (error) {
      console.log("ERROR TO LOAD PROFILE :", error);
    } finally {
      setLoading(false);
    }
  };
  const onChangeForm = (e) => {
    const { name, value } = e.target;
    setForm((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onClickSubmit = async () => {
    try {
      setSubmit(true);
      if (!form.firstname || !form.lastname || !form.email || !form.password) {
        return setError(true);
      }

      setLoading(true);

      await Auth.signUp({
        username: form.email,
        password: form.password,
        email: form.email,
      });
      await api.post("/userrequests", {
        ...form,
        register_status: "S",
      });
      alert("สร้างผู้ใช้งานแล้ว");
    } catch (error) {
    } finally {
      setLoading(false);
      setForm("");
    }
  };

  if (loading) {
    return <div>Loading....</div>;
  }
  return (
    <div className="pl-72 mb-10">
      <div className=" mt-20 text-xl font-bold text-orange-600">
        รวม Account ที่ส่งคำขอสมัครสมาชิก
      </div>
      <div className="grid grid-cols-3 ">
        {userrequest?.map((userrequests, index) => {
          return (
            <UserrequestsCard
              loadUserrequest={loadUserrequest}
              key={index}
              userrequests={userrequests}
            />
          );
        })}
      </div>
    </div>
  );
};

export default UserrequestsMain;
