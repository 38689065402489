import React from "react";
import api from "../../api";
import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";

const CreateUser = () => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState([]);
  const [error, setError] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    loadProfile();
  }, []);

  const loadProfile = async () => {
    try {
      setLoading(true);
      const { data: responseData } = await api.get(`/profiles?`, {
        params: {
          register_status: "P",
        },
      });
      console.log("responseData", responseData);
      setProfile(responseData?.profiles);
    } catch (error) {
      console.log("ERROR TO LOAD PROFILE :", error);
    } finally {
      setLoading(false);
    }
  };
  const onChangeForm = (e) => {
    const { name, value } = e.target;
    setForm((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const onClickSubmit = async () => {
    try {
      setSubmit(true);
      if (!form.firstname || !form.lastname || !form.email || !form.password) {
        return setError(true);
      }
      if (form.password.length < 6) {
        return alert("กรุณากรอกรหัสผ่าน 6 หลักขึ้นไป");
      }
      setLoading(true);

      await Auth.signUp({
        username: form.email,
        password: form.password,
        email: form.email,
      });
      await api.post("/profiles", {
        ...form,
        register_status: "S",
      });
      alert("สร้างผู้ใช้งานแล้ว");
    } catch (error) {
    } finally {
      setLoading(false);
      setForm("");
    }
  };

  if (loading) {
    return <div>Loading....</div>;
  }

  return (
    <div className="pl-72 mb-10">
      <div className="bg-white">
        <div className=" ml-40 ">
          <div className="max-w-lg w-full space-y-7 ">
            <div className="bg-white shadow-xl rounded-xl justify-center w-auto md:w-full h-auto p-10 md:p-20 mt-10 md:mt-20 lg:mt-10 xl:mt-20">
              <div>
                <h1 className="text-lezzon-blue text-center text-2xl font-bold ">
                  Create User
                </h1>
              </div>
              <div className="mt-8 space-y-6">
                <div className="rounded-md -space-y-px ">
                  <div className="my-3  ">
                    <div className=" flex mb-2 ">
                      <div className="text-gray-400 text-sm my-1 font-light">
                        {" "}
                      </div>
                      <input
                        className="font-light appearance-none rounded relative block w-full px-3 py-3 border border-gray-300
                    placeholder-asso-grayassociation  focus:outline-none  focus:z-10 text-sm"
                        type="text"
                        autoComplete="text"
                        name="firstname"
                        placeholder="Firstname"
                        value={form.firstname}
                        onChange={onChangeForm}
                      />

                      <input
                        className="font-light appearance-none rounded relative block w-full px-3 ml-2 py-3 border border-gray-300
                    placeholder-asso-grayassociation  focus:outline-none  focus:z-10 text-sm"
                        type="text"
                        autoComplete="text"
                        name="lastname"
                        placeholder="Lastname"
                        value={form.lastname}
                        onChange={onChangeForm}
                      />
                    </div>

                    <input
                      className="font-light appearance-none rounded relative block w-full px-3 py-3 border border-gray-300
                    placeholder-asso-grayassociation  focus:outline-none  focus:z-10 text-sm"
                      type="email"
                      autoComplete="email"
                      name="email"
                      placeholder=" email"
                      value={form.email}
                      onChange={onChangeForm}
                    />
                    <div className="my-2 " />

                    <div
                      className="flex justify-between font-light appearance-none rounded relative  w-full px-3 py-3 border border-gray-300 0
                    placeholder-asso-grayassociation focus:outline-none  focus:z-10 sm:text-sm"
                    >
                      <input
                        className="font-light appearance-none rounded relative block
                     focus:outline-none  focus:z-10 text-sm"
                        autoComplete="password"
                        name="password"
                        placeholder="Password 6 - 20 characters"
                        value={form.password}
                        onChange={onChangeForm}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <button
                onClick={onClickSubmit}
                className="bg-blue-500 hover:bg-blue-400 text-white text-base w-full h-8 lg:h-12 rounded-lg "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
