import { create } from "zustand";
import { persist } from "zustand/middleware";

const store = (set) => ({
  user: null,
  setUser: (user) => set(() => ({ user })),
  clearUser: () => set(() => ({ user: null })),
});

export const useUserStore = create(
  persist(store, {
    getStorage: () => sessionStorage, // (optional) by default the 'localStorage' is used
  })
);
