import React from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { onImageUploadBefore } from "../../utils/useFunctions";

const FieldWysiwyg = ({ value, setValue }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useLayoutEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  if (loaded) {
    return (
      <SunEditor
        showToolbar={true}
        onChange={(event) => {
          setValue(event);
        }}
        setContents={value}
        setDefaultStyle="height: auto ;text-align:left"
        onImageUploadBefore={onImageUploadBefore}
        setOptions={{
          minHeight: 500,
          buttonList: [
            ["font", "fontSize", "align", "fontColor", "hiliteColor"],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["horizontalRule", "list", "table"],
            ["image", "link"],
            ["undo", "redo"],
          ],
        }}
      />
    );
  } else {
    return <div>Loading...</div>;
  }
};

export default FieldWysiwyg;
