import React from "react";
import { API, Auth } from "aws-amplify";
import {
  ApproveButton,
  RejectButton,
} from "../../components/Attributes/Button";
import { getPathUrl } from "../../function";
import api from "../../api";
import { EmitterStatus } from "../../event/EmitterStatus";

const UserrequestsCard = ({ userrequests, loadUserrequest }) => {
  const onClickReject = async () => {
    try {
      await api.put(`/userrequests/${userrequests?._id}`, {
        register_status: "F",
      });
      await loadUserrequest();
    } catch (error) {
      console.log("ERROR TO LOAD PROFILE :", error);
    } finally {
    }
  };

  const onClickApprove = async () => {
    try {
      const password = new Date().getTime();
      console.log("password", password);
      await Auth.signUp({
        username: userrequests.email,
        password: `${password}`,
        attributes: {
          ["custom:_id"]: userrequests._id,
          email: userrequests.email,
        },
      });
      await api.put(`/userrequests/${userrequests?._id}`, {
        register_status: "S",
      });
      await loadUserrequest();
    } catch (error) {
      await EmitterStatus("ERROR", { text: error });
    } finally {
    }
  };
  return (
    <div className=" ">
      <div className="bg-white justify-center shadow-md w-72 h-40 p-2 rounded-lg m-1  cursor-pointer flex flex-col  flex-grow grid-cols-1  mt-10  hover:shadow-stone-300 hover:shadow-xl duration-300  text-left ">
        <div className="text-left border-b-2 space-x-1 text-base h-auto w-auto  pt-2 px-3">
          อีเมล: {userrequests?.email}
        </div>
        <div className="text-left border-b-2 space-x-1 text-base h-auto w-auto pt-2 px-3">
          ชื่อ: {userrequests?.firstname} {userrequests?.lastname}
        </div>
        <div className="text-left border-b-2 space-x-1 text-base h-auto w-auto pt-2 px-3">
          เบอร์ติดต่อ: {userrequests?.telephone}
        </div>
        <div className="text-left border-b-2 space-x-1 text-base h-auto w-auto pt-2 px-3">
          ชื่อบริษัท: {userrequests?.register_message}
        </div>
        <div className="flex justify-between">
          {/* <div className=" my-5">
        <ApproveButton onClick={onClickApprove} />
      </div> */}
          {/* <div className=" my-5">
        <RejectButton onClick={onClickReject} />
      </div> */}
        </div>
      </div>
    </div>
  );
};

export default UserrequestsCard;
