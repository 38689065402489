import { FieldUploadMultiple } from "../../components/Field";
import { FieldUpload } from "../../components/Field";
import api from "../../api";
import { successAlert } from "../../components/Modals/successAlert";
import { Storage } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getPathUrl } from "../../function";

const SponserMain = () => {
  const [sponserhome, setSponserHome] = useState([]);
  const [loadingSponserHome, setLoadingSponserHome] = useState(false);

  const [sponsermiddleprice, setSponserMiddlePrice] = useState([]);
  const [loadingSponserMiddlePrice, setLoadingSponserMiddlePrice] =
    useState(false);

  const [sponsermemberlist, setSponserMemberList] = useState([]);
  const [loadingSponserMemberList, setLoadingSponserMemberList] =
    useState(false);

  const [sponsercontact, setSponserContact] = useState([]);
  const [loadingSponserContact, setLoadingSponserContact] = useState(false);

  const [sponserlogin, setSponserLogin] = useState([]);
  const [loadingSponserLogin, setLoadingSponserLogin] = useState(false);

  const [sponserrelation, setSponserRelation] = useState("");
  const [loadingSponserRelation, setLoadingSponserRelation] = useState(false);

  const [sponseractivity, setSponserActivity] = useState([]);
  const [loadingSponserActivity, setLoadingSponserActivity] = useState(false);

  const [sponsernews, setSponserNews] = useState([]);
  const [loadingSponserNews, setLoadingSponserNews] = useState(false);

  useEffect(() => {
    loadSponser();
  }, []);

  const loadSponser = async () => {
    try {
      const { data: sponserhome } = await api.get("/sponsers", {
        params: {
          sponser_type: "HOME",
        },
      });
      if (sponserhome?.sponsers?.length > 0) {
        // setLoadingSponserHome(true);
        setSponserHome(
          sponserhome?.sponsers[0]?.sponser_images.map((home_image) => {
            return {
              url: home_image,
              type: "cloud",
            };
          })
        );
      }

      const { data: sponsermiddleprice } = await api.get("/sponsers", {
        params: {
          sponser_type: "MIDDLEPRICE",
        },
      });
      if (sponsermiddleprice?.sponsers?.length > 0) {
        // setLoadingSponserMiddlePrice(true);
        setSponserMiddlePrice(
          sponsermiddleprice?.sponsers[0]?.sponser_images.map(
            (sponsermiddleprice_image) => {
              return {
                url: sponsermiddleprice_image,
                type: "cloud",
              };
            }
          )
        );
      }

      const { data: sponsermemberlist } = await api.get("/sponsers", {
        params: {
          sponser_type: "MEMBERLIST",
        },
      });
      if (sponsermemberlist?.sponsers?.length > 0) {
        // setLoadingSponserMemberList(true);
        setSponserMemberList(
          sponsermemberlist?.sponsers[0]?.sponser_images.map(
            (sponsermemberlist_image) => {
              return {
                url: sponsermemberlist_image,
                type: "cloud",
              };
            }
          )
        );
      }

      const { data: sponsercontact } = await api.get("/sponsers", {
        params: {
          sponser_type: "CONTACT",
        },
      });
      if (sponsercontact?.sponsers?.length > 0) {
        // setLoadingSponserContact(true);
        setSponserContact(
          sponsercontact?.sponsers[0]?.sponser_images.map(
            (sponsercontact_image) => {
              return {
                url: sponsercontact_image,
                type: "cloud",
              };
            }
          )
        );
      }

      const { data: sponserlogin } = await api.get("/sponsers", {
        params: {
          sponser_type: "LOGIN",
        },
      });
      if (sponserlogin?.sponsers?.length > 0) {
        // setLoadingSponserLogin(true);
        setSponserLogin(
          sponserlogin?.sponsers[0]?.sponser_images.map(
            (sponserlogin_image) => {
              return {
                url: sponserlogin_image,
                type: "cloud",
              };
            }
          )
        );
      }

      const { data: sponserrelation } = await api.get("/sponsers", {
        params: {
          sponser_type: "RELATIONS",
        },
      });
      if (sponserrelation?.sponsers?.length > 0) {
        // setLoadingSponserRelation(true);
        setSponserRelation(
          sponserrelation?.sponsers[0]?.sponser_images.map(
            (sponserrelation_image) => {
              return {
                url: sponserrelation_image,
                type: "cloud",
              };
            }
          )
        );
      }

      const { data: sponseractivity } = await api.get("/sponsers", {
        params: {
          sponser_type: "ACTIVITY",
        },
      });
      if (sponseractivity?.sponsers?.length > 0) {
        // setLoadingSponserActivity(true);
        setSponserActivity(
          sponseractivity?.sponsers[0]?.sponser_images.map(
            (sponsersactivity_image) => {
              return {
                url: sponsersactivity_image,
                type: "cloud",
              };
            }
          )
        );
      }
      const { data: sponsernews } = await api.get("/sponsers", {
        params: {
          sponser_type: "NEWS",
        },
      });
      if (sponsernews?.sponsers?.length > 0) {
        // setLoadingSponserNews(true);
        setSponserNews(
          sponsernews?.sponsers[0]?.sponser_images.map((sponsersnews_image) => {
            return {
              url: sponsersnews_image,
              type: "cloud",
            };
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingSponserHome(false);
      setLoadingSponserMiddlePrice(false);
      setLoadingSponserMemberList(false);
      setLoadingSponserContact(false);
      setLoadingSponserLogin(false);
      setLoadingSponserRelation(false);
      setLoadingSponserActivity(false);
      setLoadingSponserNews(false);
    }
  };

  const onClickUploadMultiple = async (sponserId, sponserType) => {
    try {
      let themupload = [];

      if (sponserhome.length > 0 && sponserType === "HOME") {
        setLoadingSponserHome(true);
        for (const sponserhomeItem of sponserhome) {
          if (sponserhomeItem?.type === "cloud") {
            themupload.push(sponserhomeItem.url);
            continue;
          }

          const { file, url } = sponserhomeItem;
          const keyUpload = `${new Date().getTime()}`;
          themupload.push(keyUpload);
          await Storage.put(keyUpload, file);
        }
      }

      if (sponsermiddleprice.length > 0 && sponserType === "MIDDLEPRICE") {
        setLoadingSponserMiddlePrice(true);
        for (const sponsermiddlepriceItem of sponsermiddleprice) {
          if (sponsermiddlepriceItem?.type === "cloud") {
            themupload.push(sponsermiddlepriceItem.url);
            continue;
          }

          const { file, url } = sponsermiddlepriceItem;
          const keyUpload = `${new Date().getTime()}`;
          themupload.push(keyUpload);
          await Storage.put(keyUpload, file);
        }
      }

      if (sponsermemberlist.length > 0 && sponserType === "MEMBERLIST") {
        setLoadingSponserMemberList(true);

        for (const sponsermemberlistItem of sponsermemberlist) {
          if (sponsermemberlistItem?.type === "cloud") {
            themupload.push(sponsermemberlistItem.url);
            continue;
          }

          const { file, url } = sponsermemberlistItem;
          const keyUpload = `${new Date().getTime()}`;
          themupload.push(keyUpload);
          await Storage.put(keyUpload, file);
        }
      }

      if (sponsercontact.length > 0 && sponserType === "CONTACT") {
        setLoadingSponserContact(true);

        for (const sponsercontactItem of sponsercontact) {
          if (sponsercontactItem?.type === "cloud") {
            themupload.push(sponsercontactItem.url);
            continue;
          }

          const { file, url } = sponsercontactItem;
          const keyUpload = `${new Date().getTime()}`;
          themupload.push(keyUpload);
          await Storage.put(keyUpload, file);
        }
      }

      if (sponserlogin.length > 0 && sponserType === "LOGIN") {
        setLoadingSponserLogin(true);

        for (const sponserloginItem of sponserlogin) {
          if (sponserloginItem?.type === "cloud") {
            themupload.push(sponserloginItem.url);
            continue;
          }

          const { file, url } = sponserloginItem;
          const keyUpload = `${new Date().getTime()}`;
          themupload.push(keyUpload);
          await Storage.put(keyUpload, file);
        }
      }

      if (sponserrelation.length > 0 && sponserType === "RELATIONS") {
        setLoadingSponserRelation(true);

        for (const sponserrelationItem of sponserrelation) {
          if (sponserrelationItem?.type === "cloud") {
            themupload.push(sponserrelationItem.url);
            continue;
          }

          const { file, url } = sponserrelationItem;
          const keyUpload = `${new Date().getTime()}`;
          themupload.push(keyUpload);
          await Storage.put(keyUpload, file);
        }
      }

      if (sponseractivity.length > 0 && sponserType === "ACTIVITY") {
        setLoadingSponserActivity(true);

        for (const sponseractivityItem of sponseractivity) {
          if (sponseractivityItem?.type === "cloud") {
            themupload.push(sponseractivityItem.url);
            continue;
          }

          const { file, url } = sponseractivityItem;
          const keyUpload = `${new Date().getTime()}`;
          themupload.push(keyUpload);
          await Storage.put(keyUpload, file);
        }
      }

      if (sponsernews.length > 0 && sponserType === "NEWS") {
        setLoadingSponserNews(true);

        for (const sponsernewsItem of sponsernews) {
          if (sponsernewsItem?.type === "cloud") {
            themupload.push(sponsernewsItem.url);
            continue;
          }

          const { file, url } = sponsernewsItem;
          const keyUpload = `${new Date().getTime()}`;
          themupload.push(keyUpload);
          await Storage.put(keyUpload, file);
        }
      }

      console.log("themupload", themupload);
      await api.put("/sponsers/" + sponserId, {
        sponser_images: themupload,
        sponser_type: sponserType,
      });
      successAlert();
      loadSponser();
    } catch (error) {
      console.log("error", error);
      alert(error);
    } finally {
    }
  };

  return (
    <div className=" pl-72 font-Prompt mx-auto">
      <ToastContainer />
      <div className=" text-xl mt-5"> SPONSOR HOME </div>
      <div className=" text-lg mt-2 text-gray-500"> ส่วนของ SPONSOR HOME </div>

      <FieldUploadMultiple
        loadSponser={loadSponser}
        name="sponserhome"
        disabledUpload={loadingSponserHome}
        value={sponserhome}
        sponserId={"646f0a9461156235873cc40b"}
        sponserType={"HOME"}
        onClickUploadMultiple={() =>
          onClickUploadMultiple("646f0a9461156235873cc40b", "HOME")
        }
        setValue={setSponserHome}
        imageStyle={" w-65 object-fill "}
        label={"อัปโหลดแบนเนอร์หน้าHome"}
      />
      <div className=" text-xl mt-5"> SPONSOR MIDDLEPRICE </div>
      <div className=" text-lg mt-2 text-gray-500">
        {" "}
        ส่วนของ SPONSOR ราคากลาง{" "}
      </div>

      <FieldUploadMultiple
        loadSponser={loadSponser}
        name="sponsermiddleprice"
        disabledUpload={loadingSponserMiddlePrice}
        value={sponsermiddleprice}
        sponserId={"646f0a9461156235873cc41b"}
        sponserType={"MIDDLEPRICE"}
        onClickUploadMultiple={() =>
          onClickUploadMultiple("646f0a9461156235873cc41b", "MIDDLEPRICE")
        }
        setValue={setSponserMiddlePrice}
        imageStyle={" w-65 object-fill "}
        label={"อัปโหลดแบนเนอร์หน้าHome"}
      />
      <div className=" text-xl mt-5"> SPONSOR MEMBERLIST </div>
      <div className=" text-lg mt-2 text-gray-500">
        {" "}
        ส่วนของ SPONSOR รายชื่อสมาชิก{" "}
      </div>

      <FieldUploadMultiple
        loadSponser={loadSponser}
        name="sponsermemberlist"
        disabledUpload={loadingSponserMemberList}
        value={sponsermemberlist}
        sponserId={"646f0a9461156235873cc42b"}
        sponserType={"MEMBERLIST"}
        onClickUploadMultiple={() =>
          onClickUploadMultiple("646f0a9461156235873cc42b", "MEMBERLIST")
        }
        setValue={setSponserMemberList}
        imageStyle={" w-65 object-fill "}
        label={"อัปโหลดแบนเนอร์หน้าHome"}
      />
      <div className=" text-xl mt-5"> SPONSOR RELATIONS</div>
      <div className=" text-lg mt-2 text-gray-500">
        {" "}
        ส่วนของ SPONSOR ประชาสัมพันธ์{" "}
      </div>

      <FieldUploadMultiple
        loadSponser={loadSponser}
        name="relation"
        disabledUpload={loadingSponserRelation}
        value={sponserrelation}
        sponserId={"646f0a9461156235873cc42c"}
        sponserType={"RELATIONS"}
        onClickUploadMultiple={() =>
          onClickUploadMultiple("646f0a9461156235873cc42c", "RELATIONS")
        }
        setValue={setSponserRelation}
        imageStyle={" w-65 object-fill "}
        label={"อัปโหลดแบนเนอร์หน้าHome"}
      />
      <div className=" text-xl mt-5"> SPONSOR ACTIVITY </div>
      <div className=" text-lg mt-2 text-gray-500">
        {" "}
        ส่วนของ SPONSOR กิจกรรม{" "}
      </div>

      <FieldUploadMultiple
        loadSponser={loadSponser}
        name="activity"
        disabledUpload={loadingSponserActivity}
        value={sponseractivity}
        sponserId={"646f0a9461156235873cc43c"}
        sponserType={"ACTIVITY"}
        onClickUploadMultiple={() =>
          onClickUploadMultiple("646f0a9461156235873cc43c", "ACTIVITY")
        }
        setValue={setSponserActivity}
        imageStyle={" w-65 object-fill "}
        label={"อัปโหลดแบนเนอร์หน้าHome"}
      />
      <div className=" text-xl mt-5"> SPONSOR NEWS </div>
      <div className=" text-lg mt-2 text-gray-500">
        {" "}
        ส่วนของ SPONSOR ข่าวสาร{" "}
      </div>

      <FieldUploadMultiple
        loadSponser={loadSponser}
        name="news"
        disabledUpload={loadingSponserNews}
        value={sponsernews}
        sponserId={"646f0a9461156235873cc44c"}
        sponserType={"NEWS"}
        onClickUploadMultiple={() =>
          onClickUploadMultiple("646f0a9461156235873cc44c", "NEWS")
        }
        setValue={setSponserNews}
        imageStyle={" w-65 object-fill "}
        label={"อัปโหลดแบนเนอร์หน้าHome"}
      />
      <div className=" text-xl mt-5"> SPONSOR CONTACT </div>
      <div className=" text-lg mt-2 text-gray-500">
        {" "}
        ส่วนของ SPONSOR ติดต่อเรา{" "}
      </div>

      <FieldUploadMultiple
        loadSponser={loadSponser}
        name="sponsercontact"
        disabledUpload={loadingSponserContact}
        value={sponsercontact}
        sponserId={"646f0a9461156235873cc43b"}
        sponserType={"CONTACT"}
        onClickUploadMultiple={() =>
          onClickUploadMultiple("646f0a9461156235873cc43b", "CONTACT")
        }
        setValue={setSponserContact}
        imageStyle={" w-65 object-fill "}
        label={"อัปโหลดแบนเนอร์หน้าHome"}
      />
      <div className=" text-xl mt-5"> SPONSOR LOGIN </div>
      <div className=" text-lg mt-2 text-gray-500">
        {" "}
        ส่วนของ SPONSOR เข้าสู่ระบบ{" "}
      </div>

      <FieldUploadMultiple
        loadSponser={loadSponser}
        name="sponserlogin"
        disabledUpload={loadingSponserLogin}
        value={sponserlogin}
        sponserId={"646f0a9461156235873cc44b"}
        sponserType={"LOGIN"}
        onClickUploadMultiple={() =>
          onClickUploadMultiple("646f0a9461156235873cc44b", "LOGIN")
        }
        setValue={setSponserLogin}
        imageStyle={" w-65 object-fill "}
        label={"อัปโหลดแบนเนอร์หน้าHome"}
      />
    </div>
  );
};

export default SponserMain;
