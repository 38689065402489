import { Page } from "radium-omnibus";
import React from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { AddButton } from "../../components/Attributes/Button";
import CradleCard from "./CradleCard";
import useSweetAlert from "../../utils/useSweetAlert";
import { useEffect, useState } from "react";
import CSVReader from "react-csv-reader";
import _ from "lodash";

const pad = (number) => {
  if (parseInt(number) < 10) {
    return "0" + parseInt(number);
  }
  return number;
};

const CradleMain = () => {
  const navigate = useNavigate();
  const [cradles, setCradles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { confirm, successDeleteAlert } = useSweetAlert();

  useEffect(() => {
    loadCradles();
  }, []);

  const loadCradles = async () => {
    try {
      setLoading(true);
      const { data: responseData } = await api.get("/cradles");
      setCradles(responseData?.cradles);
      // console.log("responseData", responseData);
    } catch (error) {
      console.log("ERROR TO LOAD CRADLE :", error);
    } finally {
      setLoading(false);
    }
  };
  const editCradles = async (id) => {
    navigate(`/cradle/editCradle/${id}`);
  };

  const deleteCradle = async (id) => {
    try {
      await api.delete(`/cradles/${id}`);
      successDeleteAlert();
      await loadCradles();
    } catch (error) {
      console.error("ERROR TO DELETE CRADLE :", error);
    } finally {
    }
  };

  const importFiles = async (importDatas, fileInfo) => {
    try {
      // const { data: insurances } = await api.get(`/insurances`);
      // const { data: cartypes } = await api.get(`/cartypes`);
      // const { data: cradletypes } = await api.get(`/cradletypes`);
      // // console.log("cradletypes", cradletypes);
      // for (const importData of importDatas) {
      //   const splitWorkingDays = importData?.working_day?.split(",");
      //   let splitcradletypes = importData?.cradle_types?.split(",");
      //   let splitcartypes = importData?.car_types?.split(",");
      //   let splitinsurances = importData?.insurances?.split(",");
      //   let splitstart_date = String(importData?.start_date)?.split(" ");
      //   let splitend_date = String(importData?.end_date)?.split(" ");
      //   if (splitstart_date?.length > 0) {
      //     splitstart_date = splitstart_date[0];
      //   }
      //   if (splitend_date?.length > 0) {
      //     splitend_date = splitend_date[0];
      //   }
      //   const newWorkingDay = [];
      //   const working_day_key = {
      //     วันจันทร์: "MONDAY",
      //     วันอังคาร: "TUESDAY",
      //     วันพุธ: "WEDNESDAY",
      //     วันพฤหัสบดี: "THURSDAY",
      //     วันศุกร์: "FRIDAY",
      //     วันเสาร์: "SATURDAY",
      //     วันอาทิตย์: "SUNDAY",
      //   };
      //   let indexcradletypes = 0;
      //   if (splitcradletypes?.length > 0) {
      //     for (let splitcradletype of splitcradletypes) {
      //       if (splitcradletype) {
      //         const findExist = cradletypes?.cradletypes?.find((cradletype) => {
      //           const checkMatch =
      //             _.trim(cradletype.cradletype_name) ===
      //             _.trim(splitcradletype);
      //           return checkMatch;
      //         });
      //         if (findExist) {
      //           splitcradletypes[indexcradletypes] = findExist._id;
      //         }
      //       }
      //       indexcradletypes++;
      //     }
      //   }
      //   let indexcartypes = 0;
      //   if (splitcartypes?.length > 0) {
      //     for (let splitcartype of splitcartypes) {
      //       if (splitcartype) {
      //         const findExist = cartypes?.cartypes?.find((cartype) => {
      //           const checkMatch =
      //             _.trim(cartype.cartype_name) === _.trim(splitcartype);
      //           return checkMatch;
      //         });
      //         if (findExist) {
      //           splitcartypes[indexcartypes] = findExist._id;
      //         }
      //       }
      //       indexcartypes++;
      //     }
      //   }
      //   let indexinsurances = 0;
      //   if (splitinsurances?.length > 0) {
      //     for (let splitinsurance of splitinsurances) {
      //       if (splitinsurance) {
      //         const findExist = insurances?.insurances?.find((insurances) => {
      //           const checkMatch =
      //             _.trim(insurances.insurance_name) === _.trim(splitinsurance);
      //           return checkMatch;
      //         });
      //         if (findExist) {
      //           splitinsurances[indexinsurances] = findExist._id;
      //         }
      //       }
      //       indexinsurances++;
      //     }
      //   }
      //   let indexWorking = 0;
      //   if (splitWorkingDays?.length > 0) {
      //     for (let splitWorkingDay of splitWorkingDays) {
      //       if (_.trim(splitWorkingDay)) {
      //         splitWorkingDays[indexWorking] =
      //           working_day_key[_.trim(splitWorkingDay)];
      //       }
      //       indexWorking++;
      //     }
      //   }
      //   const form = {
      //     cradle_name: importData?.cradle_name || "",
      //     business_type: importData?.business_type || "",
      //     email: importData?.email || "",
      //     facebook: importData?.facebook || "",
      //     fax: importData?.fax || "",
      //     line: importData?.line || "",
      //     owner: importData?.owner || "",
      //     telephone: importData?.telephone || "",
      //     address: `${importData?.address_code || ""},
      //     ${importData?.address_side_road || ""},
      //     ${importData?.address_road || ""},
      //     ${importData?.address_district || ""},
      //     ${importData?.address_sub_district || ""},
      //     ${importData?.address_province || ""},
      //     ${importData?.address_postcode || ""}`,
      //     other_service: importData?.other_service || "",
      //     working_day:
      //       splitWorkingDays?.filter((splitWorkingDay) => splitWorkingDay) ||
      //       [],
      //     start_date: splitstart_date || "",
      //     end_date: splitend_date || "",
      //     cradle_types: splitcradletypes || [],
      //     car_types: splitcartypes || [],
      //     insurances: splitinsurances || [],
      //   };
      //   await api.post(`/cradles`, {
      //     ...form,
      //   });
      // }
    } catch (error) {
      alert(error);
    }
  };

  const importUpdateFiles = async (importDatas, fileInfo) => {
    try {
      for (const importData of importDatas) {
        const findAsso = await api.get(
          `/cradles?cradle_name=${importData?.cradle_name}`
        );
        if (findAsso?.data?.cradles?.length > 0) {
          const cradle = findAsso?.data?.cradles[0];
          const picture_of_front = importData.picture_of_front;
          const picture_of_staff = importData.picture_of_staff;
          const picture_of_certificate = importData.picture_of_certificate;
          const other_image_1 = importData.other_image_1;
          const other_image_2 = importData.other_image_2;

          let formUpdate = {
            images_gallery: cradle.images_gallery || [],
            cradle_image_cover: cradle.cradle_image_cover || "",
            picture_of_staff: cradle?.picture_of_staffs || "",
            picture_of_certificate: cradle?.picture_of_certificate || "",
          };
          if (picture_of_front) {
            const splitFront = picture_of_front.split("=");
            if (splitFront.length > 0) {
              formUpdate.cradle_image_cover = `${splitFront[1]}.jpg`;
            }
          }

          if (picture_of_staff) {
            const splitStaff = picture_of_staff.split("=");
            if (splitStaff.length > 0) {
              formUpdate.picture_of_staff = `${splitStaff[1]}.jpg`;
            }
          }

          if (picture_of_certificate) {
            const splitCertificate = picture_of_certificate.split("=");
            if (splitCertificate.length > 0) {
              formUpdate.picture_of_certificate = `${splitCertificate[1]}.jpg`;
            }
          }

          if (other_image_1) {
            const splitOther1 = other_image_1.split("=");
            if (splitOther1.length > 0) {
              formUpdate.images_gallery.push(`${splitOther1[1]}.jpg`);
            }
          }

          if (other_image_2) {
            const splitOther2 = other_image_2.split("=");
            if (splitOther2.length > 0) {
              formUpdate.images_gallery.push(`${splitOther2[1]}.jpg`);
            }
          }

          await api.put("/cradles/" + cradle._id, {
            ...formUpdate,
          })
        }
      }
    } catch (error) {
      alert(error);
    }
  };

  const importOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  return (
    <div className="pl-72">
      {/* <CSVReader
        parserOptions={importOptions}
        onFileLoaded={importFiles}
      >Create</CSVReader> */}
      {/* <CSVReader parserOptions={importOptions} onFileLoaded={importUpdateFiles}>
        Update Image
      </CSVReader> */}
      <div className=" ">
        <AddButton
          title={" Add Cradle + "}
          onClick={() => {
            navigate("/cradle/AddCradle");
          }}
        />
      </div>
      <div className="grid grid-cols-3 ">
        {cradles?.map((cradle, index) => {
          return (
            <CradleCard
              key={index}
              cradle={cradle}
              deleteCradle={deleteCradle}
              editCradles={editCradles}
            />
          );
        })}
      </div>
    </div>
  );
};
export default CradleMain;
