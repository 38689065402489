// import React from "react";

// const c = () => {
//   return <div>AddCradle</div>;
// };

// export default AddCradle;
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Controllers,
  FunctionCloneDeep,
  FunctionUseDebounce,
  FunctionUseId,
  Page,
} from "radium-omnibus";
import ContainerContent from "../../components/Layout/ContainerContent";
// import FieldWysiwyg from "../../components/Field/FieldWysiwyg";
import { successAlert } from "../../components/Modals/successAlert";
import api from "../../api";
import { ToastContainer } from "react-toastify";
import { Storage } from "aws-amplify";
import { FunctionUploadFileAws } from "../../utils/useFunctions";
import useToastify from "../../utils/useToastify";
import { CloudfrontUrl } from "../../function";
import Select from "react-select";
import { address } from "../../constrant/options";
import { optionsPayMent } from "../../constrant/optionscommon";

const SelectComponent = ({ value, setValue, cradletypes }) => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    setLoaded(true);
  }, 500);
  if (loaded) {
    return (
      <div>
        <div className="my-2">ประเภทอู่ {!value && "*"}</div>
        <Select
          onChange={(e) => setValue(e)}
          isMulti
          options={cradletypes}
          value={value}
        />
      </div>
    );
  } else {
    return <React.Fragment />;
  }
};

const AddCradle = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [thisId, setThisId] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const id = params?.id;
  const { notifySuccess, notifyError } = useToastify();
  const [carTypes, setCarTypes] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [cradletypes, setCradletype] = useState([]);

  useEffect(() => {
    loadAll();
    if (params.id) {
      const thisPath = params.id;
      setThisId(thisPath);
    }
  }, [params]);

  useEffect(() => {
    if (thisId) {
      LoadData();
    } else {
      return;
    }
  }, [thisId, cradletypes]);

  const loadAll = async () => {
    await fetchCarTypes();
    await fetchInsurances();
    await fetCradletypes();
  };

  // load
  const mappingData = async (cradle) => {
    return {
      ...cradle,
      working_day: cradle?.working_day.map((option) => {
        return option_days.find((option_days) => option_days.value === option);
      }),
      start_date: cradle.start_date || "",
      end_date: cradle.end_date || "",
      // start_date: option_time.find(
      //   (option) => cradle?.start_date === option.value
      // ),
      // end_date: option_time.find((option) => cradle?.end_date === option.value),

      cradle_types: cradle?.cradle_types.map((option) => {
        return cradletypes.find(
          (cradle_types) => cradle_types.value === option
        );
      }),

      car_types: cradle?.car_types.map((option) => {
        return carTypes.find((car_type) => car_type.value === option);
      }),

      payment_types: cradle?.payment_types.map((option) => {
        return optionsPayMent.find(
          (optionsPayMent) => optionsPayMent.value === option
        );
      }),

      insurances: cradle?.insurances.map((option) => {
        return insurances.find((insurances) => insurances.value === option);
      }),

      // address: address.find((option) => cradle?.address === option.value),
    };
  };

  const LoadData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/cradles/${thisId}`);

      const mapcradle = await mappingData(data?.cradle);
      setData(mapcradle);
    } catch (error) {
      console.log("ERROR TO LOAD CRADLE :", error);
    } finally {
      setLoading(false);
    }
  };
  const fetCradletypes = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/cradletypes`);
      setCradletype(
        data?.cradletypes?.map((cradletypes) => {
          return {
            value: cradletypes._id,
            label: cradletypes.cradletype_name,
          };
        })
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const fetchCarTypes = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/cartypes`);
      setCarTypes(
        data?.cartypes?.map((cartype) => {
          return {
            value: cartype._id,
            label: cartype.cartype_name,
          };
        })
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const option_days = [
    { value: "MONDAY", label: "จันทร์" },
    { value: "TUESDAY", label: "อังคาร" },
    { value: "WEDNESDAY", label: "พุธ" },
    { value: "THURSDAY", label: "พฤหัสบดี" },
    { value: "FRIDAY", label: "ศุกร์" },
    { value: "SATURDAY", label: "เสาร์" },
    { value: "SUNDAY", label: "อาทิตย์" },
  ];

  const option_time = [
    { value: "0:00", label: "0:00" },
    { value: "0:30", label: "0:30" },
    { value: "1:00", label: "1:00" },
    { value: "1:30", label: "1:30" },
    { value: "2:00", label: "2:00" },
    { value: "2:30", label: "2:30" },
    { value: "3:00", label: "3:00" },
    { value: "3:30", label: "3:30" },
    { value: "4:00", label: "4:00" },
    { value: "4:30", label: "4:30" },
    { value: "5:00", label: "5:00" },
    { value: "5:30", label: "5:30" },
    { value: "6:00", label: "6:00" },
    { value: "6:30", label: "6:30" },
    { value: "7:00", label: "7:00" },
    { value: "7:30", label: "7:30" },
    { value: "8:00", label: "8:00" },
    { value: "8:30", label: "8:30" },
    { value: "9:00", label: "9:00" },
    { value: "9:30", label: "9:30" },
    { value: "10:00", label: "10:00" },
    { value: "10:30", label: "10:30" },
    { value: "11:00", label: "11:00" },
    { value: "11:30", label: "11:30" },
    { value: "12:00", label: "12:00" },
    { value: "12:30", label: "12:30" },
    { value: "13:00", label: "13:00" },
    { value: "13:30", label: "13:30" },
    { value: "14:00", label: "14:00" },
    { value: "14:30", label: "14:30" },
    { value: "15:00", label: "15:00" },
    { value: "15:30", label: "15:30" },
    { value: "16:00", label: "16:00" },
    { value: "16:30", label: "16:30" },
    { value: "17:00", label: "17:00" },
    { value: "17:30", label: "17:30" },
    { value: "18:00", label: "18:00" },
    { value: "18:30", label: "18:30" },
    { value: "19:00", label: "19:00" },
    { value: "19:30", label: "19:30" },
    { value: "20:00", label: "20:00" },
    { value: "20:30", label: "20:30" },
    { value: "21:00", label: "21:00" },
    { value: "21:30", label: "21:30" },
    { value: "22:00", label: "22:00" },
    { value: "22:30", label: "22:30" },
    { value: "23:00", label: "23:00" },
    { value: "23:30", label: "23:30" },
  ];

  const fetchInsurances = async () => {
    try {
      setLoading(true);
      const { data } = await api.get(`/insurances`);
      setInsurances(
        data?.insurances?.map((insurance) => {
          return {
            value: insurance._id,
            label: insurance.insurance_name,
          };
        })
      );
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getMappingForm = (form) => {
    return {
      ...form,
      cradle_types: form.cradle_types?.map((cradle_type) => cradle_type.value),
      car_types: form.car_types?.map((car_type) => car_type.value),
      insurances: form.insurances?.map((insurance) => insurance.value), // [string]
      working_day: form.working_day?.map((working_day) => working_day.value),
      description: form?.description,
      other_service: form.other_service,
      map_cradle: form?.map_cradle,
      payment_types: form.payment_types?.map(
        (payment_type) => payment_type.value
      ),
    };
  };

  const onClickSubmit = async (form) => {
    let newForm = FunctionCloneDeep(form);

    newForm = {
      ...newForm,
      images_gallery: await Promise.all(
        form?.images_gallery?.map(async (image) => {
          console.log("image ", image);
          if (image?.this_images_gallery) {
            const thisImageKeyS3 = await FunctionUploadFileAws(
              image?.this_images_gallery
            );
            return thisImageKeyS3;
          } else {
            return;
          }
        })
      ),
    };

    console.log("form", newForm);

    try {
      // 1

      if (thisId) {
        // let cradle01_key = null;
        // if (form) {
        //   cradle01_key = await FunctionUploadFileAws(form.cradle_image_cover);
        // }
        await api.put(`/cradles/${thisId}`, {
          ...newForm,
          ...getMappingForm(newForm),
          cradle_image_cover: await FunctionUploadFileAws(
            newForm.cradle_image_cover
          ),
        });
        setTimeout(() => {
          navigate("/cradle");
        }, 3000);
        // notifySuccess();
      } else {
        await api.post("/cradles", {
          ...newForm,
          ...getMappingForm(newForm),
          cradle_image_cover: await FunctionUploadFileAws(
            newForm.cradle_image_cover
          ),
        });

        successAlert();
        setTimeout(() => {
          navigate("/cradle");
        }, 3000);
      }
    } catch (error) {
      console.log("error", error);
      alert("เกิดข้อผิดพลาด " + error);
    }
  };

  const thisLoadData = FunctionCloneDeep({
    ...data,
    cradle_types: data?.cradle_types?.filter((cradle_types) => cradle_types),
    car_types: data?.car_types?.filter((car_types) => car_types),
    insurances: data?.insurances?.filter((insurance) => insurance),
    images_gallery: data?.images_gallery?.map((row) => {
      return { this_images_gallery: row };
    }),
  });

  console.log("LOAD DATAA ", { data, thisLoadData });

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ContainerContent>
      <ToastContainer />
      <div className=" pl-72 text-2xl text-gray font-normal flex gap-2 items-center  ">
        <div className="border-2 border-darkgray rounded-full flex justify-center p-2 w-32">
          Create {""}
        </div>
      </div>
      <div className=" pl-72 my-10">
        <Controllers
          loadData={thisId ? thisLoadData : {}}
          classNameSubmit=" p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-auto border border-green-700 hover:border-green-500 hover:bg-green-500 text-green-700 hover:text-white  rounded-full duration-300"
          onCancel={() => {
            navigate("/cradle");
          }}
          onSubmit={onClickSubmit}
          classNameCancel=" p-0.5 cursor-pointer  flex justify-center  items-center gap-2 w-40 border border-red-700 hover:border-red-500 hover:bg-red-500 text-red-700 hover:text-white  rounded-full duration-300 "
          classNameWrapper="flex gap-3 items-center justify-center"
          fields={[
            {
              label: "ชื่ออู่",

              name: "cradle_name",
              type: "text",

              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            {
              label: "ช่องทางติดต่อ",

              name: "telephone",
              type: "text",

              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            {
              label: "fax",

              name: "fax",
              type: "text",

              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            {
              label: "email",

              name: "email",
              type: "text",

              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            {
              label: "line",

              name: "line",
              type: "text",

              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            {
              label: "facebook",

              name: "facebook",
              type: "text",

              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            {
              label: "website",

              name: "website",
              type: "text",

              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            {
              type: "customs",
              name: "working_day",
              fieldCustoms: ({ value, setValue }) => {
                return (
                  <div>
                    <div className="mt-2 "></div>

                    <div className="">เลือกวันทำงาน ( เลือกได้มากกว่า 1 )</div>
                    <Select
                      isMulti
                      options={option_days}
                      value={value}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(event) => {
                        setValue(event);
                      }}
                    />
                  </div>
                );
              },
            },
            // {
            //   type: "customs",
            //   name: "start_date",
            //   fieldCustoms: ({ value, setValue }) => {
            //     return (
            //       <div>
            //         <div className="mt-2"></div>
            //         <div className="grid grid-cols-1  md:grid-cols-2 gap-2">
            //           <div className="grid grid-cols-1">
            //             <div>เวลาเริ่มงาน</div>
            //             <Select
            //               options={option_time}
            //               value={value}
            //               onChange={(event) => {
            //                 setValue(event);
            //               }}
            //               placeholder="เวลาเริ่มงาน"
            //             />
            //           </div>
            //         </div>
            //       </div>
            //     );
            //   },
            // },
            {
              label: "เวลาเริ่มงาน",
              name: "start_date",
              type: "text",
              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2 ",
            },
            {
              label: "เวลาเลิกงาน",
              name: "end_date",
              type: "text",
              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2 ",
            },
            // {
            //   type: "customs",
            //   name: "end_date",
            //   fieldCustoms: ({ value, setValue }) => {
            //     return (
            //       <div>
            //         <div className="mt-2"></div>
            //         <div className="grid grid-cols-1  md:grid-cols-2 gap-2">
            //           <div className="grid grid-cols-1">
            //             <div>เวลาเลิกงาน</div>
            //             <Select
            //               options={option_time}
            //               onChange={(e) => setValue(e)}
            //               value={value}
            //               placeholder="เวลาเลิกงาน"
            //             />
            //           </div>
            //         </div>
            //       </div>
            //     );
            //   },
            // },
            {
              label: "คำอธิบาย",
              name: "description",
              type: "text",
              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            {
              label: "บริการอื่นๆ",
              name: "other_service",
              type: "text",
              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            {
              label: "สถานที่ตั้ง",
              name: "location",
              type: "text",
              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            {
              label: "การเดินทาง",
              name: "travel",
              type: "text",
              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            {
              label: "สิ่งอำนวยความสะดวก",
              name: "facilities",
              type: "text",
              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            {
              label: "รายละเอียดเพิ่มเติม",
              name: "moredetails",
              type: "text",
              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            {
              label: "ตำแหน่งที่ตั้ง",
              name: "map_cradle",
              type: "text",
              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            {
              type: "customs",
              name: "cradle_types",
              fieldCustoms: ({ value, setValue }) => {
                return (
                  <SelectComponent
                    value={value}
                    setValue={setValue}
                    cradletypes={cradletypes}
                  />
                );
              },
            },
            {
              type: "customs",
              name: "car_types",
              fieldCustoms: ({ value, setValue }) => {
                return (
                  <div>
                    <div className="my-2">ประเภทรถ {!value && "*"}</div>
                    <Select
                      onChange={(e) => setValue(e)}
                      isMulti
                      options={carTypes}
                      value={value}
                    />
                  </div>
                );
              },
            },
            // {
            //   type: "customs",
            //   name: "payment_types",
            //   fieldCustoms: ({ value, setValue }) => {
            //     return (
            //       <div>
            //         <div className="my-2">ระบบการชำระเงิน {!value && "*"}</div>
            //         <Select
            //           value={value}
            //           onChange={(e) => setValue(e)}
            //           isMulti
            //           options={optionsPayMent}
            //         />
            //       </div>
            //     );
            //   },
            // },
            {
              type: "customs",
              name: "insurances",
              fieldCustoms: ({ value, setValue }) => {
                return (
                  <div>
                    <div className="my-2">ประกันภัย {!value && "*"}</div>
                    <Select
                      onChange={(e) => setValue(e)}
                      isMulti
                      options={insurances}
                      value={value}
                    />
                  </div>
                );
              },
            },
            // {
            //   label: "เลขที่",
            //   name: "additional_address",
            //   type: "text",
            //   // required: true,
            //   renderError: ({ error }) => {
            //     return "";
            //   },
            //   classNameInput:
            //     "border border-yellowbutton w-full h-8 rounded-md mb-4",
            //   classNameLabel: "textgray font-light mb-2",
            // },
            {
              label: "ที่อยู่",
              name: "address",
              type: "text",
              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 p-4",
              classNameLabel: "textgray font-light mb-2",
            },
            // {
            //   name: "address",
            //   type: "customs",
            //   // required: true,
            //   renderError: ({ error }) => {
            //     return "";
            //   },
            //   classNameInput:
            //     "border border-yellowbutton w-full h-8 rounded-md mb-4",
            //   classNameLabel: "textgray font-light mb-2",
            //   fieldCustoms: ({ value, setValue }) => {
            //     return (
            //       <div>
            //         <div className="my-2">
            //           ที่อยู่ตามไปรษณีย์ {!value && "*"}
            //         </div>
            //         <Select
            //           value={value}
            //           onChange={(e) => setValue(e)}
            //           options={address}
            //         />
            //       </div>
            //     );
            //   },
            // },
            {
              label: "File (รูปปก)",
              name: "cradle_image_cover",
              type: "file",
              classNameLabel: "textgray font-light mb-2",
              getPathUrl: CloudfrontUrl,
              // required: true,

              renderError: ({ error }) => {
                return "";
              },

              classNameInput:
                "mt-4 p-0.5 cursor-pointer  flex justify-center font-light items-center  w-40 border border-green-700 hover:border-green-500 hover:bg-green-500 text-green-700 hover:text-white  rounded-full duration-300 ",
            },
            // {
            //   label: "File (เพิ่มเติม)",
            //   name: "images_gallery",
            //   type: "file",
            //   classNameLabel: "textgray font-light mb-2",
            //   getPathUrl: CloudfrontUrl,
            //   // required: true,
            //   isMultiple: true,

            //   renderError: ({ error }) => {
            //     return "";
            //   },
            //   classNameInput:
            //     "p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-40 border border-green-700 hover:border-green-500 hover:bg-green-500 text-green-700 hover:text-white  rounded-full duration-300 ",
            // },
            {
              // coverComponent: ({ children }) => {
              //   return <div className="grid grid-cols-4">{children}</div>;
              // },
              classNameCore: "",
              key: "images_gallery",
              childType: "array",
              fields: [
                {
                  label: "File (เพิ่มเติม)",
                  name: "this_images_gallery",
                  type: "file",
                  classNameLabel: "textgray font-light mb-2",
                  getPathUrl: CloudfrontUrl,
                  // required: true,
                  renderError: ({ error }) => {
                    return "";
                  },
                  classNameInput:
                    "mt-4 p-0.5 cursor-pointer  flex justify-center font-light items-center  w-40 border border-green-700 hover:border-green-500 hover:bg-green-500 text-green-700 hover:text-white  rounded-full duration-300 ",
                },
              ],
              classNameAdd:
                "mt-4 bg-blue-500 hover:bg-blue-400 px-4 py-1 text-white hover:text-blueGray rounded-full text-center w-40 cursor-pointer",
              classNameRemove:
                " mt-4 border border-red-700 hover:border-red-500 hover:bg-red-500 rounded-full text-center  text-red-500 hover:text-white  w-40 cursor-pointer",
            },
          ]}
        />
      </div>
      <div className="grid grid-cols-2 col-span-4"></div>
    </ContainerContent>
  );
};
export default AddCradle;
