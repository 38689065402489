import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Controllers, Page } from "radium-omnibus";
import ContainerContent from "../../components/Layout/ContainerContent";
// import FieldWysiwyg from "../../components/Field/FieldWysiwyg";
import { successAlert } from "../../components/Modals/successAlert";
import api from "../../api";
import { ToastContainer } from "react-toastify";
import { Storage } from "aws-amplify";
import { CloudfrontUrl, FunctionUploadFileAws } from "../../utils/useFunctions";
import useToastify from "../../utils/useToastify";

const AddCartype = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [thisId, setThisId] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const id = params?.id;
  const { notifySuccess, notifyError } = useToastify();

  useEffect(() => {
    if (params.id) {
      const thisPath = params.id;
      setThisId(thisPath);
    }
  }, [params]);

  useEffect(() => {
    if (thisId) {
      LoadData();
    } else {
      return;
    }
  }, [thisId]);

  const LoadData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/cartypes/${thisId}`);
      setData(data?.cartype);
    } catch (error) {
      console.log("ERROR TO LOAD CARTYPE :", error);
    } finally {
      setLoading(false);
    }
  };

  const onClickSubmit = async (form) => {
    try {
      if (thisId) {
        let cartype01_key = null;
        if (form) {
          cartype01_key = await FunctionUploadFileAws(form.cartype_image);
        }
        await api.put(`/cartypes/${thisId}`, {
          ...form,
          cartype_image: cartype01_key,
        });
        navigate("/cartype");
        notifySuccess();
      } else {
        let cartype01_key = null;
        if (form) {
          cartype01_key = await FunctionUploadFileAws(form.cartype_image);
        }
        await api.post("/cartypes", {
          cartype_name: form.cartype_name,
          cartype_image: cartype01_key,
        });

        successAlert();
        setTimeout(() => {
          navigate("/cartype");
        }, 3000);
      }
    } catch (error) {
      console.log("error", error);
      alert("เกิดข้อผิดพลาด " + error);
    }
  };

  return (
    <ContainerContent>
      <ToastContainer />
      <div className=" pl-72 text-2xl text-gray font-normal flex gap-2 items-center">
        <div className="border border-darkgray rounded-full flex justify-center w-52 p-2">
          Create Cartype {""}
        </div>
      </div>
      <div className=" pl-72 my-10 ">
        <Controllers
          loadData={data}
          classNameSubmit=" p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-40 border border-green-700 hover:border-green-500 hover:bg-green-500 text-green-700 hover:text-white  rounded-full duration-300"
          onCancel={() => {
            navigate("/cartype");
          }}
          onSubmit={onClickSubmit}
          classNameCancel=" p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-40 border border-red-700 hover:border-red-500 hover:bg-red-500 text-red-700 hover:text-white  rounded-full duration-300 "
          classNameWrapper="flex gap-3 items-center justify-center"
          fields={[
            {
              label: "Name",
              name: "cartype_name",
              type: "text",
              required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4",
              classNameLabel: "textgray font-light mb-2",
            },

            {
              label: "File",
              name: "cartype_image",
              type: "file",
              classNameLabel: "textgray font-light mb-2",
              getPathUrl: CloudfrontUrl,
              // required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-40 border border-green-700 hover:border-green-500 hover:bg-green-500 text-green-700 hover:text-white  rounded-full duration-300 ",
            },
          ]}
        />
      </div>
    </ContainerContent>
  );
};
export default AddCartype;
