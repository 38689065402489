import React, { useId } from "react";

function Input({ ...props }) {
  const inputId = useId();

  return (
    <div class="flex justify-center">
      <div class="mb-3 w-full">
        <label for={inputId} class="form-label inline-block mb-2 text-gray-700">
          {props?.label}
        </label>
        <input
          type="text"
          class="
        form-control
        block
        w-full
        px-3
        py-1.5
        text-base
        font-normal
        text-gray-700
        bg-white bg-clip-padding
        border border-solid border-gray-300
        rounded
        transition
        ease-in-out
        m-0
        focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
      "
          id={inputId}
          {...props}
        />
      </div>
    </div>
  );
}

export default Input;
