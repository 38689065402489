import Resizer from "react-image-file-resizer";
import { Storage } from "aws-amplify";

export const CloudfrontUrl = "https://d1yj9gj3wwvssr.cloudfront.net/public/";

export const getPathUrl = (key) => {
  //ดึง path ของรูปภาพจาก S3
  if (key) {
    return `${CloudfrontUrl}${key}`;
  } else {
    return;
  }
};

export const checkUrl = (path = "") => {
  const checkUrl = String(path).includes(CloudfrontUrl);
  return checkUrl;
};

export const isImageS3 = (path) => {
  if (path && typeof path === "string") {
    return true;
  }
  return false;
};

export const AddUrlToFile = (file) => {
  if (isImageS3(file)) {
    return getPathUrl(file);
  }

  if (!file) return;
  if (file) {
    const src = URL.createObjectURL(file);
    return src;
  }
};

export const UploadFileAws = async (file) => {
  if (file) {
    const keyUpload = `${new Date().getTime()}_${file?.name}`;
    await Storage.put(keyUpload, file);
    return keyUpload;
  } else {
    return;
  }
};

export const onImageUploadBefore = (files, info, uploadHandler) => {
  if (!files && files === null && files === undefined && files < 0) return;
  (async () => {
    const responseStorage = await UploadFileAws(files[0]);
    const responseUpload = {
      result: [
        {
          url: `https://dashboardassobdb059558f784d72b8e6843e252ac028160536-dev.s3.ap-southeast-1.amazonaws.com/public/${responseStorage}`,
          name: files[0].name,
          size: files[0].size,
        },
      ],
    };
    await uploadHandler(responseUpload);
  })();
  uploadHandler();
};

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export const NEAR_PLACE_ICONS = {
  Hospital: "/images/hospital.png",
  Airport: "/images/airport.png",
  Mall: "/images/shoppingbag.png",
  Expressway: "/images/expressway.png",
  Market: "/images/market.png",
};
