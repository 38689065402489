// import React from "react";

// const AddCradletype = () => {
//   return <div>AddCradletype</div>;
// };

// export default AddCradletype;
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Controllers, Page } from "radium-omnibus";
import ContainerContent from "../../components/Layout/ContainerContent";
// import FieldWysiwyg from "../../components/Field/FieldWysiwyg";
import { successAlert } from "../../components/Modals/successAlert";
import api from "../../api";
import { ToastContainer } from "react-toastify";
import { Storage } from "aws-amplify";
import { CloudfrontUrl, FunctionUploadFileAws } from "../../utils/useFunctions";
import useToastify from "../../utils/useToastify";

const AddCradletype = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [thisId, setThisId] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const id = params?.id;
  const { notifySuccess, notifyError } = useToastify();

  useEffect(() => {
    if (params.id) {
      const thisPath = params.id;
      setThisId(thisPath);
    }
  }, [params]);

  useEffect(() => {
    if (thisId) {
      LoadData();
    } else {
      return;
    }
  }, [thisId]);

  const LoadData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/cradletypes/${thisId}`);
      setData(data?.cradletype);
    } catch (error) {
      console.log("ERROR TO LOAD CRADLETYPE :", error);
    } finally {
      setLoading(false);
    }
  };

  const onClickSubmit = async (form) => {
    console.log("form", form);
    try {
      if (thisId) {
        let cradletype01_key = null;
        if (form) {
          cradletype01_key = await FunctionUploadFileAws(form.cradletype_image);
        }
        await api.put(`/cradletypes/${thisId}`, {
          ...form,
          cradletype_image: cradletype01_key,
        });
        navigate("/cradletype");
        notifySuccess();
      } else {
        let cradletype01_key = null;
        if (form) {
          cradletype01_key = await FunctionUploadFileAws(form.cradletype_image);
        }
        await api.post("/cradletypes", {
          cradletype_name: form.cradletype_name,
        });

        successAlert();
        setTimeout(() => {
          navigate("/cradletype");
        }, 3000);
      }
    } catch (error) {
      console.log("error", error);
      alert("เกิดข้อผิดพลาด " + error);
    }
  };

  return (
    <ContainerContent>
      <ToastContainer />
      <div className=" pl-72 text-2xl text-gray font-normal flex gap-2 items-center ">
        <div className="border border-darkgray rounded-full flex justify-center w-56 p-2">
          Create Cradletype {""}
        </div>
      </div>
      <div className=" pl-72 ">
        <Controllers
          loadData={data}
          classNameSubmit=" p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-40 border border-green-700 hover:border-green-500 hover:bg-green-500 text-green-700 hover:text-white  rounded-full duration-300"
          onCancel={() => {
            navigate("/cradletype");
          }}
          onSubmit={(f) => {
            onClickSubmit(f);
          }}
          classNameCancel=" p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-40 border border-red-700 hover:border-red-500 hover:bg-red-500 text-red-700 hover:text-white  rounded-full duration-300 "
          classNameWrapper="flex gap-3 items-center justify-center"
          fields={[
            {
              label: "Name",
              name: "cradletype_name",
              type: "text",
              required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4",
              classNameLabel: "textgray font-light mb-2",
            },
          ]}
        />
      </div>
    </ContainerContent>
  );
};
export default AddCradletype;
