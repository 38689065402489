import { toast } from "react-toastify";

export const successAlert = () => {
  toast.success("บันทึกข้อมูลสำเร็จ", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const successEditAlert = () => {
  toast.success("บันทึกข้อมูลสำเร็จ", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const successDeleteAlert = () => {
  toast.success("ลบข้อมูลสำเร็จ", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
export const warnAlert = () => {
  toast.warn("กรุณากรอรกข้อมูลให้ครบถ้วน", {
    position: "top-center",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return;
};
