import { FieldUploadMultiple } from "../../components/Field";
import { FieldUpload } from "../../components/Field";
import api from "../../api";
import { successAlert } from "../../components/Modals/successAlert";
import { Storage } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

const BannerMain = () => {
  const [loadingBanner, setLoadingBanner] = useState(false);
  const [bannerId, setBannerId] = useState("");

  const [bannerhome, setBannerHome] = useState([]);
  const [loadingBannerHome, setLoadingBannerHome] = useState(false);

  const [banner00, setBanner00] = useState("");
  const [loadingBanner00, setLoadingBanner00] = useState(false);

  const [banner01, setBanner01] = useState("");
  const [loadingBanner01, setLoadingBanner01] = useState(false);

  const [banner02, setBanner02] = useState("");
  const [loadingBanner02, setLoadingBanner02] = useState(false);

  const [banner03, setBanner03] = useState("");
  const [loadingBanner03, setLoadingBanner03] = useState(false);

  const [banner04, setBanner04] = useState("");
  const [loadingBanner04, setLoadingBanner04] = useState(false);

  useEffect(() => {
    loadBanner();
  }, []);

  const loadBanner = async () => {
    try {
      setLoadingBanner(true);

      const { data } = await api.get("/banners");

      if (data.banners.length > 0) {
        setBannerId(data.banners[0]._id);
        setBannerHome(
          data?.banners[0]?.home_images.map((home_image) => {
            return {
              url: home_image,
              type: "cloud",
            };
          })
        );
        if (data?.banners[0]?.home_image_00) {
          setBanner00({ url: data?.banners[0]?.home_image_00, type: "cloud" });
        }
        if (data?.banners[0]?.home_image_01) {
          setBanner01({ url: data?.banners[0]?.home_image_01, type: "cloud" });
        }
        if (data?.banners[0]?.home_image_02) {
          setBanner02({ url: data?.banners[0]?.home_image_02, type: "cloud" });
        }
        if (data?.banners[0]?.home_image_03) {
          setBanner03({ url: data?.banners[0]?.home_image_03, type: "cloud" });
        }
        if (data?.banners[0]?.home_image_04) {
          setBanner04({ url: data?.banners[0]?.home_image_04, type: "cloud" });
        }
      } else {
        const { data } = await api.post("/banners");
        setBannerId(data.bannerId);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingBanner(false);
    }
  };

  const onClickUploadMultiple = async () => {
    try {
      setLoadingBannerHome(true);

      let bannerHomes = [];
      if (bannerhome.length > 0) {
        for (const bannerhomeItem of bannerhome) {
          const { file, url } = bannerhomeItem;
          const keyUpload = `${new Date().getTime()}`;
          bannerHomes.push(keyUpload);
          await Storage.put(keyUpload, file);
        }
      }

      await api.put("/banners/" + bannerId, {
        home_images: bannerHomes,
      });
      successAlert();
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingBannerHome(false);
    }
  };

  const onClickUploadBanner00 = async () => {
    try {
      setLoadingBanner00(true);

      let banner00_key = null;
      if (banner00) {
        const fileName = new Date().getTime();
        banner00_key = fileName;
        await Storage.put(fileName, banner00?.file);
      }
      await api.put("/banners/" + bannerId, {
        home_image_00: banner00_key,
      });
      successAlert();
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingBanner00(false);
    }
  };

  const onClickUploadBanner01 = async () => {
    try {
      setLoadingBanner01(true);

      let banner01_key = null;
      if (banner01) {
        const fileName = new Date().getTime();
        banner01_key = fileName;
        await Storage.put(fileName, banner01?.file);
      }

      await api.put("/banners/" + bannerId, {
        home_image_01: banner01_key,
      });
      successAlert();
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingBanner01(false);
    }
  };

  const onClickUploadBanner02 = async () => {
    try {
      setLoadingBanner02(true);

      let banner02_key = null;
      if (banner02) {
        const fileName = new Date().getTime();
        banner02_key = fileName;
        await Storage.put(fileName, banner02?.file);
      }

      await api.put("/banners/" + bannerId, {
        home_image_02: banner02_key,
      });
      successAlert();
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingBanner02(false);
    }
  };

  const onClickUploadBanner03 = async () => {
    try {
      setLoadingBanner03(true);

      let banner03_key = null;
      if (banner03) {
        const fileName = new Date().getTime();
        banner03_key = fileName;
        await Storage.put(fileName, banner03?.file);
      }

      await api.put("/banners/" + bannerId, {
        home_image_03: banner03_key,
      });
      successAlert();
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingBanner03(false);
    }
  };

  const onClickUploadBanner04 = async () => {
    try {
      setLoadingBanner04(true);

      let banner04_key = null;
      if (banner04) {
        const fileName = new Date().getTime();
        banner04_key = fileName;
        await Storage.put(fileName, banner04?.file);
      }

      await api.put("/banners/" + bannerId, {
        home_image_04: banner04_key,
      });
      successAlert();
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingBanner04(false);
    }
  };

  if (loadingBanner) {
    return <div>Loading...</div>;
  }

  return (
    <div className=" pl-72 font-Prompt mx-auto">
      <ToastContainer />
      {/* <div className=" text-xl mt-5"> BANNER HOME </div>
      <div className=" text-lg mt-2 text-gray-500">
        {" "}
        ส่วนของ BANNER HOME ขนาด 1719 x 623 px{" "}
      </div>

      <FieldUploadMultiple
        name="bannerhome"
        disabledUpload={loadingBannerHome}
        value={bannerhome}
        onClickUploadMultiple={onClickUploadMultiple}
        setValue={setBannerHome}
        imageStyle={" w-65 object-fill "}
        label={"อัปโหลดแบนเนอร์หน้าHome"}
      /> */}

      <div className=" text-xl mt-5"> BANNER HOME </div>
      <div className=" text-lg mt-2 text-gray-500">
        {" "}
        ส่วนของ Banner ขนาด 3051 x 1067 px{" "}
      </div>
      <FieldUpload
        name="banner00"
        value={banner00}
        disabledUpload={loadingBanner00}
        onClickUpload={onClickUploadBanner00}
        setValue={setBanner00}
        imageStyle={" w-65 h-60 object-fill "}
        label={"อัปโหลดแบนเนอร์หน้าHome"}
      />

      <div className=" text-xl mt-5"> BANNER HOME01 </div>
      <div className=" text-lg mt-2 text-gray-500">
        {" "}
        ส่วนของโฆษณา ขนาด 3051 x 1067 px{" "}
      </div>
      <FieldUpload
        name="banner01"
        value={banner01}
        disabledUpload={loadingBanner01}
        onClickUpload={onClickUploadBanner01}
        setValue={setBanner01}
        imageStyle={" w-65 h-60 object-fill "}
        label={"อัปโหลดแบนเนอร์หน้าHome"}
      />
      <div className=" text-xl mt-5"> Banner ข่าวสาร </div>
      <div className=" text-lg mt-2 text-gray-500">
        {" "}
        ส่วนของข่าวสาร ขนาด 2399 x 277 px{" "}
      </div>
      <FieldUpload
        name="banner02"
        value={banner02}
        disabledUpload={loadingBanner02}
        onClickUpload={onClickUploadBanner02}
        setValue={setBanner02}
        imageStyle={" w-65 h-60 object-fill "}
        label={"อัปโหลดแบนเนอร์หน้าHome"}
      />
      <div className=" text-xl mt-5"> Banner กิจกรรม </div>
      <div className=" text-lg mt-2 text-gray-500">
        {" "}
        ส่วนของกิจกรรม ขนาด 2399 x 277 px{" "}
      </div>
      <FieldUpload
        name="banner03"
        value={banner03}
        disabledUpload={loadingBanner03}
        onClickUpload={onClickUploadBanner03}
        setValue={setBanner03}
        imageStyle={" w-65 h-60 object-fill "}
        label={"อัปโหลดแบนเนอร์หน้าHome"}
      />
      <div className=" text-xl mt-5"> Banner ประชาสัมพันธ์ </div>
      <div className=" text-lg mt-2 text-gray-500">
        {" "}
        ส่วนของประชาสัมพันธ์ ขนาด 2399 x 277 px{" "}
      </div>
      <FieldUpload
        name="banner04"
        value={banner04}
        disabledUpload={loadingBanner04}
        onClickUpload={onClickUploadBanner04}
        setValue={setBanner04}
        imageStyle={" w-65 h-60 object-fill "}
        label={"อัปโหลดแบนเนอร์หน้าHome"}
      />
    </div>
  );
};
export default BannerMain;
