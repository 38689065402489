import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";

function Sidebar({ setShowSidebar, showSidebar }) {
  const [collapseShow, setCollapseShow] = useState("hidden");

  const location = useLocation();
  const isActive = location?.pathname === "/login";

  const getNavLink = (props) => {
    return (
      <li className=" items-center ">
        <NavLink
          {...props}
          // activeStyle={{
          //     color: "black"
          // }}
          // activeclassName= "border-b border-blue-600 w-24"
          //   style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className={({ isActive }) =>
            classNames(
              "py-3 font-medium block duration-300 transition-all text-blue-400 hover:text-blue-800",
              {
                ["border-b border-red-600 w-24"]: isActive,
              }
            )
          }
          //   className={
          //     " py-3 font-medium block duration-300 transition-all " +
          //     " text-blue-400 hover:text-blue-800 "
          //   }
        >
          <div className="flex justify-center  ">
            <span className="">{props.icon}</span>
            <span className="text-center text-lg">{props.text}</span>
          </div>
        </NavLink>
      </li>
    );
  };

  if (isActive) return null;

  if (!showSidebar)
    return (
      <div
        className="fixed top-0 left-0  text-white z-20 p-3"
        onClick={() => setShowSidebar(true)}
      ></div>
    );

  return (
    <>
      <nav className="  md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4">
        <div className=" md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto font-Prompt ">
          <div className=" text-left md:pb-2 text-blueGray-600   whitespace-nowrap text-sm uppercase font-bold p-4  flex flex-row items-center">
            <img src="logo.png" className="  mx-auto" />
          </div>
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100  md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            <ul
              className=" flex flex-col justify-center items-center  list-none  space-y-4"
              onClick={() => setCollapseShow("hidden")}
            >
              {/* {getNavLink({
                text: "HOME",
                to: "/",
                exact: true,
              })} */}
              {getNavLink({
                text: "Banner",
                to: "/banner",
                exact: true,
              })}
              {getNavLink({
                text: "Blog",
                to: "/blog",
                exact: true,
              })}
              {getNavLink({
                text: "Youtube",
                to: "/youtube",
                exact: true,
              })}
              {getNavLink({
                text: "Cartype",
                to: "/cartype",
                exact: true,
              })}
              {getNavLink({
                text: "Cradletype",
                to: "/cradletype",
                exact: true,
              })}
              {getNavLink({
                text: "Insurance",
                to: "/insurance",
                exact: true,
              })}
              {getNavLink({
                text: "Cradle",
                to: "/cradle",
                exact: true,
              })}
              {getNavLink({
                text: "Create User",
                to: "/createuser",
                exact: true,
              })}
              {getNavLink({
                text: "User",
                to: "/profile",
                exact: true,
              })}
              {getNavLink({
                text: "User Request",
                to: "/userrequest",
                exact: true,
              })}
              {getNavLink({
                text: "Sponsor",
                to: "/sponser",
                exact: true,
              })}
              <div className="border-b border-gray-200"></div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Sidebar;
