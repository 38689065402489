import classNames from "classnames";
import React from "react";

export const Button = ({ text, onClick, className = "", svg }) => {
  return (
    <button
      onClick={(e) => onClick(e)}
      className={classNames(
        "p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-40 border border-green-700 hover:border-green-500 hover:bg-green-500 text-green-700 hover:text-white  rounded-full duration-300 ",
        className
      )}
    >
      {text}
      {svg}
    </button>
  );
};

export const DeleteButton = ({ onClick, className }) => {
  return (
    <div className=" flex justify-end">
      <div
        onClick={onClick}
        className={classNames(
          " p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-40 border border-red-700 hover:border-red-500 hover:bg-red-500 text-red-700 hover:text-white  rounded-full duration-300 ",
          className
        )}
      >
        <div>Delete</div>
      </div>
    </div>
  );
};

export const AddButton = ({ onClick, title }) => {
  return (
    <div className=" my-5">
      <button
        type="button"
        className="p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-40 border border-green-700 hover:border-green-500 hover:bg-green-500 text-green-700 hover:text-white  rounded-full duration-300 "
        onClick={onClick}
      >
        {title}
      </button>
    </div>
  );
};

export const DeleteButtonTable = ({ onClick }) => {
  return (
    <button
      className=" text-red-700 hover:text-red-500 duration-300 "
      onClick={onClick}
    >
      DeleteButtonTable
    </button>
  );
};
  export const RejectButton = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        className="p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-20 border border-red-700 hover:border-red-500 hover:bg-red-500 text-red-700 hover:text-white  rounded-full duration-300 "
      >reject
      </button>
    );
  };

export const ApproveButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-20 border border-green-700 hover:border-green-500 hover:bg-green-500 text-green-700 hover:text-white  rounded-full duration-300 "
    >approve
    </button>
  );
};
