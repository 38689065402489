import { Page } from "radium-omnibus";
import React from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { AddButton } from "../../components/Attributes/Button";
import CradletypeCard from "./CradletypeCard";
import useSweetAlert from "../../utils/useSweetAlert";
import { useEffect, useState } from "react";

const CradletypeMain = () => {
  const navigate = useNavigate();
  const [cradletypes, setCradletypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { confirm, successDeleteAlert } = useSweetAlert();

  useEffect(() => {
    loadCradletypes();
  }, []);

  const loadCradletypes = async () => {
    try {
      setLoading(true);
      const { data: responseData } = await api.get("/cradletypes");
      setCradletypes(responseData?.cradletypes);
    } catch (error) {
      console.log("ERROR TO LOAD CRADLETYPE :", error);
    } finally {
      setLoading(false);
    }
  };
  const editCradletypes = async (id) => {
    navigate(`/Cradletype/editCradletype/${id}`);
  };

  const deleteCradletype = async (id) => {
    try {
      await api.delete(`/cradletypes/${id}`);
      successDeleteAlert();
      await loadCradletypes();
    } catch (error) {
      console.error("ERROR TO DELETE CRADLETYPE :", error);
    } finally {
    }
  };

  return (
    <div className="pl-72 mb-10">
      <div className=" ">
        <AddButton
          title={" Add Cradletype + "}
          onClick={() => {
            navigate("/cradletype/AddCradletype");
          }}
        />
      </div>
      <div className="grid grid-cols-3 ">
        {cradletypes?.map((Cradletype, index) => {
          return (
            <CradletypeCard
              key={index}
              cradletype={Cradletype}
              deleteCradletype={deleteCradletype}
              editCradletypes={editCradletypes}
            />
          );
        })}
      </div>
    </div>
  );
};
export default CradletypeMain;
