import React from "react";
import { useLocation } from "react-router-dom";
import { useUserStore } from "../../_store/userStore";

const Logout = () => {
  const clearUser = useUserStore((state) => state.clearUser);
  const user = useUserStore((state) => state.user);
  //   const location = useLocation();
  //   const isActive = location?.pathname === "/login";

  return (
    <div>
      <button
        onClick={() => {
          clearUser();
        }}
        className="md:mr-1 lg:mr-4 xl:mr-5 cursor-pointer text-white bg-red-700 text-sm
          px-4 md:px-1 lg:px-3 xl:px-4 py-1 rounded-full text-center  drop-shadow-lg"
      >
        ออกจากระบบ
      </button>
    </div>
  );
};

export default Logout;
{
  /* <button
          type="botton"
          onClick={() => {
            Auth.signOut();
            localStorage.clear();
            dispatch(initializeApp());
          }}
          className={classNames(
            `md:mr-1 lg:mr-4 xl:mr-5 cursor-pointer text-white
          px-4 md:px-1 lg:px-3 xl:px-4 py-1 rounded-full text-center  drop-shadow-lg`,
            {
              ["bg-lezzon-red text-white"]: isActive,
              ["bg-lezzon-red"]: !isActive,
            }
          )}
        >
          ออกจากระบบ
        </button> */
}
