import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Controllers, Page } from "radium-omnibus";
import ContainerContent from "../../components/Layout/ContainerContent";
import FieldWysiwyg from "../../components/Field/FieldWysiwyg";
import { successAlert } from "../../components/Modals/successAlert";
import api from "../../api";
import { ToastContainer } from "react-toastify";
import { Storage } from "aws-amplify";
import { FunctionUploadFileAws } from "../../utils/useFunctions";
import useToastify from "../../utils/useToastify";
import { Select } from "react-select-virtualized";
import { optionsBlogType } from "../../constrant/optionscommon";
import index from "toastify";
import { CloudfrontUrl } from "../../function";

const AddBlog = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [thisId, setThisId] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const id = params?.id;
  const { notifySuccess, notifyError } = useToastify();

  useEffect(() => {
    if (params.id) {
      const thisPath = params.id;
      setThisId(thisPath);
    }
  }, [params]);

  useEffect(() => {
    if (thisId) {
      LoadData();
    } else {
      return;
    }
  }, [thisId]);
  // หาค่าเดิม
  const mappingData = (blog) => {
    return {
      ...blog,
      blog_type: optionsBlogType.find(
        (option) => blog?.blog_type === option.value
      ),
    };
  };

  const LoadData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/blogs/${thisId}`);

      const mapBlog = mappingData(data?.blog);
      console.log("mapBlog : ", mapBlog);
      setData(mapBlog);
    } catch (error) {
      console.log("ERROR TO LOAD BLOG :", error);
    } finally {
      setLoading(false);
    }
  };

  const onClickSubmit = async (form) => {
    try {
      if (thisId) {
        await api.put(`/blogs/${thisId}`, {
          ...form,
          cover_image: await FunctionUploadFileAws(form.cover_image),
          blog_type: form.blog_type,
        });
        navigate("/blog");
        notifySuccess();
      } else {
        await api.post("/blogs", {
          ...form,
          cover_image: await FunctionUploadFileAws(form.cover_image),
          blog_type: form.blog_type,
        });

        successAlert();
        setTimeout(() => {
          navigate("/blog");
        }, 3000);
      }
    } catch (error) {
      console.log("error", error);
      alert("เกิดข้อผิดพลาด " + error);
    }
  };

  return (
    <ContainerContent>
      <ToastContainer />
      <div className=" pl-72 text-2xl text-gray font-normal flex gap-2 items-center">
        <div className="border border-darkgray rounded-full flex justify-center w-44 h-10">
          Create Blog {""}
        </div>
      </div>
      <div className=" pl-72 my-10">
        <Controllers
          loadData={data}
          classNameSubmit=" p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-40 border border-green-700 hover:border-green-500 hover:bg-green-500 text-green-700 hover:text-white  rounded-full duration-300"
          onCancel={() => {
            navigate("/blog");
          }}
          onSubmit={onClickSubmit}
          classNameCancel=" p-0.5 cursor-pointer  flex justify-center font-light items-center gap-2 w-40 border border-red-700 hover:border-red-500 hover:bg-red-500 text-red-700 hover:text-white  rounded-full duration-300 "
          classNameWrapper="flex gap-3 items-center justify-center"
          fields={[
            {
              label: "Title",
              name: "title",
              type: "text",
              required: true,
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "border border-yellowbutton w-full h-8 rounded-md mb-4 pl-4",
              classNameLabel: "textgray font-light mb-2",
            },
            // {
            //   label: "Description",
            //   name: "desc",
            //   type: "text",
            //   required: true,
            //   renderError: ({ error }) => {
            //     return "";
            //   },
            //   classNameInput:
            //     "border border-yellowbutton w-full h-8 rounded-md mb-4 pl-4",
            //   classNameLabel: "textgray font-light mb-2",
            // },
            {
              type: "customs",
              name: "blog_type",
              fieldCustoms: ({ value, setValue }) => {
                return (
                  <div className=" ">
                    <div className="my-2">เลือกหัวข้อ </div>
                    <Select
                      className="z-50"
                      value={value}
                      onChange={(e) => setValue(e)}
                      options={optionsBlogType}
                    />
                  </div>
                );
              },
            },
            {
              label: "File",
              name: "cover_image",
              getPathUrl: CloudfrontUrl,
              classNameSubLabel: (props) => {
                console.log("VALI", props);
              },
              type: "file",
              classNameLabel: "textgray font-light mb-2",
              renderError: ({ error }) => {
                return "";
              },
              classNameInput:
                "mt-4 p-0.5 cursor-pointer  flex justify-center font-light items-center  w-40 border border-green-700 hover:border-green-500 hover:bg-green-500 text-green-700 hover:text-white  rounded-full duration-300 ",
            },
            {
              type: "customs",
              name: "content",
              fieldCustoms: ({ setValue, value }) => {
                return (
                  <div className="mt-4">
                    <FieldWysiwyg value={value} setValue={setValue} />
                  </div>
                );
              },
            },
          ]}
        />
      </div>
    </ContainerContent>
  );
};
export default AddBlog;
