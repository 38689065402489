import { Page } from "radium-omnibus";
import React from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { AddButton } from "../../components/Attributes/Button";
import CartypeCard from "./CartypeCard";
import useSweetAlert from "../../utils/useSweetAlert";
import { useEffect, useState } from "react";

const CartypeMain = () => {
  const navigate = useNavigate();
  const [cartypes, setCartypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { confirm, successDeleteAlert } = useSweetAlert();

  useEffect(() => {
    loadCartypes();
  }, []);

  const loadCartypes = async () => {
    try {
      setLoading(true);
      const { data: responseData } = await api.get("/cartypes");
      setCartypes(responseData?.cartypes);
    } catch (error) {
      console.log("ERROR TO LOAD CARTYPE :", error);
    } finally {
      setLoading(false);
    }
  };
  const editCartypes = async (id) => {
    navigate(`/Cartype/editCartype/${id}`);
  };

  const deleteCartype = async (id) => {
    try {
      await api.delete(`/cartypes/${id}`);
      successDeleteAlert();
      await loadCartypes();
    } catch (error) {
      console.error("ERROR TO DELETE CARTYPE :", error);
    } finally {
    }
  };

  return (
    <div className="pl-72 mb-10">
      <div className=" ">
        <AddButton
          title={" Add Cartype + "}
          onClick={() => {
            navigate("/cartype/AddCartype");
          }}
        />
      </div>
      <div className="grid grid-cols-3 ">
        {cartypes?.map((cartype, index) => {
          return (
            <CartypeCard
              key={index}
              cartype={cartype}
              deleteCartype={deleteCartype}
              editCartypes={editCartypes}
            />
          );
        })}
      </div>
    </div>
  );
};
export default CartypeMain;
