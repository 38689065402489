import { Page } from "radium-omnibus";
import React from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import { AddButton } from "../../components/Attributes/Button";
import BlogCard from "./BlogCard";
import useSweetAlert from "../../utils/useSweetAlert";
import { useEffect, useState } from "react";

const BlogMain = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const { confirm, successDeleteAlert } = useSweetAlert();

  useEffect(() => {
    loadBlogs();
  }, []);

  const loadBlogs = async () => {
    try {
      setLoading(true);
      const { data: responseData } = await api.get("/blogs");
      setBlogs(responseData?.blogs);
    } catch (error) {
      console.log("ERROR TO LOAD BLOGS :", error);
    } finally {
      setLoading(false);
    }
  };
  const editBlogs = async (id) => {
    navigate(`/blog/editBlog/${id}`);
  };

  const deleteBlog = async (id) => {
    try {
      await api.delete(`/blogs/${id}`);
      successDeleteAlert();
      await loadBlogs();
    } catch (error) {
      console.error("ERROR TO DELETE BLOG :", error);
    } finally {
    }
  };

  return (
    <div className="pl-72 ">
      <div className=" text-xl mt-5"> BLOG </div>
      <div className=" text-lg mt-2 text-gray-500">
        {" "}
        แนะนำขนาดรูปปก 960 x 600 px{" "}
      </div>
      <div className="">
        <AddButton
          title={" Add Blog + "}
          onClick={() => {
            navigate("/blog/AddBlog");
          }}
        />
      </div>
      <div className="grid grid-cols-3 mb-10">
        {blogs?.map((blog, index) => {
          return (
            <BlogCard
              key={index}
              blog={blog}
              deleteBlog={deleteBlog}
              editBlogs={editBlogs}
            />
          );
        })}
      </div>
    </div>
  );
};
export default BlogMain;
